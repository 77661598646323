import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  medium: () => ({
    styles: `
      width: 2.25rem;
      height: 2.25rem;
    `,
  }),
  small: () => ({
    styles: `
      width: 1.25rem;
      height: 1.25rem;
    `,
  }),
  xSmall: () => ({
    styles: `
      width: 0.875rem;
      height: 0.875rem;
    `,
  }),
  rounded: () => ({
    styles: `
      border-radius: 100%;
    `,
  }),
  white: ({ theme }) => ({
    styles: `
      svg, g, path {
        fill: ${theme.farmProject.gray_3};
        stroke: ${theme.farmProject.gray_3};
      }
    `,
  }),
  inverted: ({ theme }) => ({
    styles: `
      svg, g, path {
        fill: ${theme.farmProject.light_gray};
        stroke: ${theme.farmProject.light_gray};
      }
    `,
  }),
  invertedStroke: ({ theme }) => ({
    styles: `
      * {
        stroke: ${theme.farmProject.light_gray};
      }
    `,
  }),
  invertedFill: ({ theme }) => ({
    styles: `
      * {
        fill: ${theme.farmProject.light_gray};
        polygon {
          fill: ${theme.farmProject.light_gray};
        }
      }
    `,
  }),
  darkGrayColor: ({ theme }) => ({
    styles: `
      svg, g {
        fill: ${theme.farmProject.dark_gray};
        stroke: ${theme.farmProject.dark_gray};
      }
    `,
  }),
  darkGrayStroke: ({ theme }) => ({
    styles: `
      svg, g, path {
        stroke: ${theme.farmProject.dark_gray};
        fill: transparent;
      }
    `,
  }),
  brandColor: ({ theme }) => ({
    styles: `
      svg, g, path {
        fill: ${theme.farmProject.green};
      }
    `,
  }),
  brandStroke: ({ theme }) => ({
    styles: `
      svg, g, path {
        stroke: ${theme.farmProject.brand_primary};
        fill: transparent;
      }
    `,
  }),
  errorStroke: ({ theme }) => ({
    styles: `
      svg, g, path {
        stroke: ${theme.farmProject.error};
        fill: transparent;
      }
    `,
  }),
  transparent: () => ({
    styles: `
      svg {
        path {
          fill: transparent;
        }
      }
    `,
  }),
  rotate90: () => ({
    styles: `
    transform: rotate(90deg);
    `,
  }),
  rotate180: () => ({
    styles: `
    transform: rotate(180deg);
    `,
  }),
  rotate270: () => ({
    styles: `
    transform: rotate(270deg);
    `,
  }),
  roundedWhiteBg: ({ theme }) => ({
    styles: `
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      margin-left: auto;
      border-radius: 1rem;
      background-color: ${theme.farmProject.white};
      cursor: pointer;

      svg {
        max-width: 44%;
        max-height: 44%;
      }
    `,
  }),
};

export const IconStyled = styled.div
  .withConfig({
    shouldForwardProp: (prop) => !['modifiers'].includes(prop),
  })
  .attrs({
    className: 'Icon',
  })`
  width: 3.25rem;
  height: 3.25rem;
  line-height: 1;
  background-color: transparent;
  display: block;
  overflow: hidden;
  position: relative;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
  ${applyStyleModifiers(modifiers)}

  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
