import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'enums';
import { useAuth } from 'utils/auth';

const Profile = () => {
  const { user, logout } = useAuth();

  return (
    <div className='flex flex-row'>
      <div>
        <span>Welcome {user.firstName} | </span>
        <Link to={Paths.LOGIN} onClick={logout}>
          logout
        </Link>
      </div>
    </div>
  )
}

export default Profile;