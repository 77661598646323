import { Modal } from 'antd';

const GrowModal = ({ isOpen, onClose, header, content, actions, size }) => {
  return (
    <Modal title={header} open={isOpen} onCancel={onClose} width={size} footer={actions} centered>
      {content}
    </Modal>
  );
};

export default GrowModal;
