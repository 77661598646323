import React from 'react';

const List = ({ values, columns = 1, isNumeral = false, customClass = '', renderListItem }) => {
  const columnClass = `columns-${columns}`;

  const renderDefault = (item, idx) => {
    return (
      <li key={idx} className='text-sm text-gray-500 mb-2'>
        {item}
      </li>
    );
  };

  const renderItem = (item, idx) => {
    const itemRender = renderListItem ? renderListItem : renderDefault;
    return itemRender(item, idx);
  }

  if (values.lenght === 0) {
    return null;
  }

  return (
    <div className={customClass}>
      <ul className={`${columnClass} ${isNumeral ? 'list-decimal pl-6' : ''} gap-4`}>
        {values.map(renderItem)}
      </ul>
    </div>
  );
}

export default List;
