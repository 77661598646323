import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import ButtonText from '../../../atoms/button-text';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  goToNextPage,
  goToLastPage,
  goToPreviousPage,
  goToFirstPage,
  pageIndex,
  pageOptions,
}) => {
  return (
    <div className='flex items-center justify-between py-3'>
      <div className='flex justify-between flex-1 sm:hidden'>
        <ButtonText onClick={goToPreviousPage} disabled={!canPreviousPage}>
          Previous
        </ButtonText>
        <ButtonText onClick={goToNextPage} disabled={!canNextPage}>
          Next
        </ButtonText>
      </div>
      <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
        <div className='flex items-baseline gap-x-2'>
          <span className='text-sm text-gray-700'>
            Page <span className='font-medium'>{pageIndex + 1}</span> of <span className='font-medium'>{pageOptions.length}</span>
          </span>
        </div>
        <nav className='relative z-0 inline-flex -space-x-px rounded-md shadow-sm' aria-label='Pagination'>
          <ButtonText className='rounded-l-md' onClick={goToFirstPage} disabled={!canPreviousPage}>
            <span className='sr-only'>First</span>
            <ChevronDoubleLeftIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </ButtonText>
          <ButtonText onClick={goToPreviousPage} disabled={!canPreviousPage}>
            <span className='sr-only'>Previous</span>
            <ChevronLeftIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </ButtonText>
          <ButtonText onClick={goToNextPage} disabled={!canNextPage}>
            <span className='sr-only'>Next</span>
            <ChevronRightIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </ButtonText>
          <ButtonText className='rounded-r-md' onClick={goToLastPage} disabled={!canNextPage}>
            <span className='sr-only'>Last</span>
            <ChevronDoubleRightIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </ButtonText>
        </nav>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  goToNextPage: PropTypes.func,
  goToLastPage: PropTypes.func,
  goToPreviousPage: PropTypes.func,
  goToFirstPage: PropTypes.func,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
};

export default Pagination;
