import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../utils/theme';

import { IconStyled, modifiers } from './Icon.styled';

const Icon = ({ modifiers, children, onClick, className, attrs }) => (
  <ThemeProvider theme={theme}>
    <IconStyled modifiers={modifiers} onClick={onClick} tabIndex={onClick ? 0 : -1} className={className} {...attrs}>
      {children}
    </IconStyled>
  </ThemeProvider>
);

export default Icon;
export { modifiers };
