import { useRef } from 'react';
import PropTypes from 'prop-types';

import { SquaredButton } from 'elements';

const UploadButton = ({
  onUpload,
  onClick,
  id,
  type,
  disabled,
  label,
  modifiers,
  as,
  target,
  className,
  dataNw,
  ariaLabel,
  ariaSelected,
  children,
}) => {
  const inputEl = useRef(null);

  const handleInputClick = () => {
    inputEl.current.click();
  };

  const handleClick = () => {
    onClick?.();
    handleInputClick();
  };

  return (
    <SquaredButton
      id={id}
      type={type}
      disabled={disabled}
      onClick={handleClick}
      label={label}
      modifiers={modifiers}
      as={as}
      target={target}
      className={className}
      dataNw={dataNw}
      ariaLabel={ariaLabel}
      ariaSelected={ariaSelected}
      children={children}
      component='label'
    >
      <input style={{ display: 'none' }} ref={inputEl} type='file' id='upload-input' accept='text/csv' onChange={onUpload} />
    </SquaredButton>
  );
};

UploadButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  modifiers: PropTypes.any,
  as: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  dataNw: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaSelected: PropTypes.string,
  children: PropTypes.node,
};

export default UploadButton;
