const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

const envConfig = {
  gcsUrlPrefix: null,
  firebaseApiKey: null,
  firebaseAuthDomain: null,
  firebaseDbURl: null,
  firebaseProjectId: null,
  firebaseStorageBucket: null,
  firebaseMessagingSenderId: null,
  environment,
};

if (environment === 'develop' || environment === 'development') {
  envConfig.gcsUrlPrefix = process.env.REACT_APP_GCS_URL_PREFIX_DEVELOPMENT;
  envConfig.firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY_DEVELOPMENT;
  envConfig.firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEVELOPMENT;
  envConfig.firebaseDbURl = process.env.REACT_APP_FIREBASE_DB_URL_DEVELOPMENT;
  envConfig.firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID_DEVELOPMENT;
  envConfig.firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEVELOPMENT;
  envConfig.firebaseMessagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEVELOPMENT;
}

if (environment === 'staging') {
  envConfig.gcsUrlPrefix = process.env.REACT_APP_GCS_URL_PREFIX_STAGING;
  envConfig.firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY_STAGING;
  envConfig.firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_STAGING;
  envConfig.firebaseDbURl = process.env.REACT_APP_FIREBASE_DB_URL_STAGING;
  envConfig.firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID_STAGING;
  envConfig.firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_STAGING;
  envConfig.firebaseMessagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_STAGING;
}

if (environment === 'production') {
  envConfig.gcsUrlPrefix = process.env.REACT_APP_GCS_URL_PREFIX;
  envConfig.firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  envConfig.firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
  envConfig.firebaseDbURl = process.env.REACT_APP_FIREBASE_DB_URL;
  envConfig.firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  envConfig.firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
  envConfig.firebaseMessagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
}

export default envConfig;
