import React from 'react';
import { Navigate } from 'react-router-dom';

import logo from 'LG_logo.svg';
import SquaredButton from '../atoms/squared-button';
import { useAuth } from 'utils/auth';
import { Paths } from 'enums';
import { FcGoogle } from 'react-icons/fc';

const Login = () => {
  const auth = useAuth();

  return (
    <div className='h-screen bg-growgreen-300 flex items-center justify-center'>
      <div className='flex flex-col items-center justify-center bg-white rounded-lg shadow-2xl p-9'>
        <div className='flex-1 w-33 pb-3'>
          <img src={logo} className='App-logo bg-growgreen-700 p-7 rounded-lg' alt='logo' />
        </div>
        {!auth.user ? (
          <div className='flex-1 w-33'>
            <SquaredButton modifiers='lined' onClick={auth.loginWithGoogle}>
              <div className='flex items-center gap-x-1.5'>
                <FcGoogle />
                Login with Google
              </div>
            </SquaredButton>
          </div>
        ) : (
          <Navigate to={Paths.PROFILE} replace={true} />
        )}
      </div>
    </div>
  );
};

export default Login;
