import _ from 'lodash';

export const createPlantTypeOptions = (plantTypes, tableData = []) => {
  const plantsInTable = Object.fromEntries(tableData.map((row) => [row.plantTypeIdString, true]));
  return _.sortBy(
    Object.keys(plantTypes)
      .filter((key) => plantTypes[key].weblistingEnabled && !plantsInTable[key])
      .map((key) => ({ value: key, label: plantTypes[key].friendlyName })),
    (option) => option.label
  );
};