import BitButtonText from '../../atoms/button-text';

const ButtonText = ({ id, type, disabled, onClick, label, modifiers, children, as, dataNw }) => {
  return (
    <BitButtonText
      id={id}
      type={type}
      disabled={disabled}
      onClick={onClick}
      label={label}
      modifier={modifiers}
      children={children}
      as={as}
      dataNw={dataNw}
    />
  );
};

export default ButtonText;
