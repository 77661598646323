import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import Select from '../atoms/select';
import SquaredButton from '../atoms/squared-button';
import { useSearchParams } from 'react-router-dom';

import { Table, PageHeaderToolbar, CentersDropdown } from 'components';
import { TableNumericInput, TableSquaredButton } from 'elements';

import { updateTableRow } from 'utils/table';
import axiosUtil from 'utils/axios';

import { CentersContext } from 'contexts/Centers';

const columns = [
  {
    Header: 'Week Number',
    accessor: 'weekNumber',
  },
  {
    Header: 'DTT Group',
    id: 'dttGroup',
    accessor: (row) => {
      return row.dttGroup.displayName;
    },
  },
  {
    Header: 'Days to Transplant',
    accessor: 'daysToTransplant',
  },
  {
    Header: 'New',
    accessor: 'newDTT',
    Cell: ({ row, onDTTBlur, onDTTChange }) => {
      return (
        <TableNumericInput
          row={row}
          onBlur={onDTTBlur}
          onChange={onDTTChange}
          label='New DTT'
          decimalDigitLimit={0}
          value={row.values.newDTT}
        />
      );
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    Cell: ({ row, onUpdateSingleDTT }) => {
      const isModified = !!row.values.newDTT;

      return (
        <>
          <TableSquaredButton
            row={row}
            disabled={!isModified}
            modifiers={isModified ? 'primary' : 'white'}
            onClick={onUpdateSingleDTT}
            label='Update'
          />
        </>
      );
    },
  },
];

const DaysToTransplant = () => {
  const [tableData, setTableData] = useState([]);
  const [dttGroups, setDttGroups] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { centersById } = useContext(CentersContext);

  const selectedGroup = searchParams.get('dttGroup');

  const centerId = searchParams.get('centerId');
  const selectedCenter = centersById[centerId];

  const updateTableData = (data) => {
    setTableData(data);
  };

  const canUpdateBatch = useMemo(() => tableData.some((row) => !!row.newDTT) > 0, [tableData]);

  const onDTTChange = (value, row) => updateTableData(updateTableRow({ newDTT: value }, row.index, tableData));

  const onDTTBlur = (value, row) => updateTableData(updateTableRow({ newDTT: value }, row.index, tableData));

  const createPayload = ({ id, dttGroup, weekNumber, newDTT }) => {
    return {
      id,
      centerId: selectedCenter.longId,
      dttGroup: {
        dttGroup: dttGroup.dttGroup,
        displayName: dttGroup.displayName,
      },
      weekNumber,
      daysToTransplant: Number(newDTT),
    };
  };

  const onSubmitDTTUpdate = async () => {
    const payloadArr = tableData.filter((row) => !!row.newDTT).map(createPayload);

    if (payloadArr.length === 0) return;

    const { success } = await axiosUtil.put(`app/protected/daysToTransplant/`, payloadArr);

    if (success) {
      getGroupData(selectedGroup, selectedCenter);
    }
  };

  const onUpdateSingleDTT = async (row) => {
    const { id, dttGroup, weekNumber } = row.original;
    const { newDTT } = row.values;
    const payload = createPayload({ id, dttGroup, weekNumber, newDTT });

    const { success } = await axiosUtil.put(`app/protected/daysToTransplant/`, [payload]);

    if (success) {
      const { success } = await axiosUtil.get(`app/protected/daysToTransplant/center/${selectedCenter.longId}/dttGroup/${selectedGroup}`);

      if (success) {
        const updatedTableData = [...tableData];
        const rowToUpdate = success.data.find((row) => row.id === payload.id);
        rowToUpdate.newDTT = '';
        updatedTableData[row.index] = rowToUpdate;
        setTableData([...updatedTableData]);
      }
    }
  };

  const getDttGroups = async () => {
    const { success, error } = await axiosUtil.get(`app/protected/daysToTransplant/dttGroup`);

    if (success) {
      const dttGroups = success.data
        .sort((groupA, groupB) => (groupA.displayName > groupB.displayName ? 1 : -1))
        .map((dttGroup) => ({ value: dttGroup.dttGroup, label: dttGroup.displayName }));
      setDttGroups([{ value: '', label: 'Select a group' }, ...dttGroups]);
    }

    if (error) console.log('error', error);
  };

  const handleCenterChange = (e, center) => {
    if (center.id) {
      searchParams.set('centerId', center.id);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('centerId');
      setSearchParams(searchParams);
      setTableData([]);
    }
  };

  const handleGroupChange = ({ target }) => {
    if (target.value) {
      searchParams.set('dttGroup', target.value);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('dttGroup');
      setSearchParams(searchParams);
      setTableData([]);
    }
  };

  const getGroupData = useCallback(async (group, center) => {
    const { success, error } = await axiosUtil.get(`app/protected/daysToTransplant/center/${center.longId}/dttGroup/${group}`);
    if (success) {
      updateTableData(success.data.map((row) => ({ ...row, newDTT: '' })));
    }

    if (error) console.log('error', error);
  }, []);

  useEffect(() => {
    if (selectedGroup && selectedCenter?.id) {
      getGroupData(selectedGroup, selectedCenter);
    }
  }, [getGroupData, selectedGroup, selectedCenter]);

  useEffect(() => {
    getDttGroups();
  }, []);

  return (
    <>
      <div className='flex flex-row gap-2 p-4'>
        <h2>Days to Transplant</h2>
      </div>
      <PageHeaderToolbar
        leftContent={[
          <CentersDropdown value={selectedCenter?.id} onChange={handleCenterChange} seedlingOnly />,
          <Select
            options={dttGroups}
            value={selectedGroup}
            label='Groups'
            onChange={handleGroupChange}
            modifiers={['compact']}
            defaultValue={null}
          />,
        ]}
        rightContent={[
          <SquaredButton modifiers={[canUpdateBatch ? '' : 'disabled', 'tertiary']} label='Update All' onClick={onSubmitDTTUpdate} />,
        ]}
      />
      <Table
        columns={columns}
        data={tableData}
        editableCellHandlers={{
          onDTTChange,
          onDTTBlur,
          onUpdateSingleDTT,
        }}
        tableContentClassName='overflow-x-auto max-h-[90vh]'
        getRowId={(tableDataRow) => tableDataRow.id}
      />
    </>
  );
};

export default DaysToTransplant;
