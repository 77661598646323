import React from 'react';
import { useLocation } from 'react-router-dom';
import ExcellentExport from 'excellentexport';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export function findObjectIndexOnArray(array, propertyName, value) {
  return array.findIndex(item => item[propertyName] === value);
}

export function exportCSVFile(filename, { data, addCurrentTimeToName }) {
  const currentTime = new Date().toJSON().split('.')[0];

  const options = {
    format: 'csv',
    filename: addCurrentTimeToName ? filename + `_${currentTime}` : filename,
    openAsDownload: true
  }

  //  Sheets must be an array of sheet configuration objects. Sheet description:
  const sheets = [
    {
      // Sheet name >> required: CSV only supports 1 page
      name: filename,
      from: {
        // Array with the data. Array where each element is a row. Every row is an array of the cells.
        array: data,
      },
    },
  ]

  ExcellentExport.convert(options, sheets);
}
