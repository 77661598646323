import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import ButtonText from '../atoms/button-text';
import { Table } from 'components';

const getData = () => {
  const data = [
    {
      name: 'John Doe',
      email: 'john.doe@example.com',
      title: 'Engineer',
      department: 'Framework',
      status: 'Active',
      role: 'Admin',
      age: 27,
    },
    {
      name: 'Mary Jane',
      email: 'mary.jane@example.com',
      title: 'Product Manager',
      department: 'Sales Management',
      status: 'Inactive',
      role: 'Owner',
      age: 43,
    },
    {
      name: 'Dr. Who',
      email: 'dr.who@example.com',
      title: 'People & Culture Leader',
      department: 'HR',
      status: 'Active',
      role: 'Member',
      age: 32,
    },
    {
      name: 'Willy Wonka',
      email: 'willy.wonka@example.com',
      title: 'Gourmet',
      department: 'Cantina',
      status: 'Offline',
      role: 'Member',
      age: 72,
    },
    {
      name: 'Sonic the Hedgehog',
      email: 'sonic@example.com',
      title: 'Transport Manager',
      department: 'Mobility',
      status: 'Inactive',
      role: 'Admin',
      age: 36,
    },
    {
      name: 'Mario Mario',
      email: 'mario.mario@example.com',
      title: 'Plumber',
      department: 'General Internal Operations',
      status: 'Active',
      role: 'Member',
      age: 52,
    },
  ];
  return [...data, ...data, ...data];
};

const TableTest = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [shouldEnablePagination, setshouldEnablePagination] = useState(true);
  const [shouldEnableGlobalFilter, setshouldEnableGlobalFilter] = useState(true);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        emailAccessor: 'email',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Department',
        accessor: 'department',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Date',
        Cell: ({ row }) => {
          const onChange = (date) => {
            setSelectedDates({ ...selectedDates, ...{ [row.id]: date } });
          };

          return <DatePicker onChange={onChange} placeholderText='select date' selected={selectedDates[row.id]} />;
        },
      },
      {
        Header: 'Actions',
        Cell: (props) => {
          const handleAction = () => {
            alert(`clicked on Row ${props.row.id}`);
          };

          return <button onClick={handleAction}>Action</button>;
        },
      },
    ],
    [selectedDates]
  );

  const data = React.useMemo(() => getData(), []);

  const onRowSelectionChange = (data) => {
    setSelectedRows(data);
  };

  return (
    <div className='w-screen h-screen bg-gray-200'>
      <h3>Table Test</h3>
      <div>
        <ButtonText modifiers='brand' onClick={() => setshouldEnablePagination(!shouldEnablePagination)} label='Toggle Pagination' />
        <ButtonText modifiers='brand' onClick={() => setshouldEnableGlobalFilter(!shouldEnableGlobalFilter)} label='Toggle GlobalFilter' />
      </div>
      <Table
        columns={columns}
        data={data}
        onRowSelectionChange={onRowSelectionChange}
        shouldEnablePagination={shouldEnablePagination}
        shouldEnableGlobalFilter={shouldEnableGlobalFilter}
      />
      <pre>
        <code>{JSON.stringify(selectedRows)}</code>
      </pre>
    </div>
  );
};

export default TableTest;
