import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect, useAsyncDebounce } from 'react-table';
import _ from 'lodash';

import { ReactTableAction } from './enums';

import Pagination from './subcomponents/Pagination';
import TableHeader from './subcomponents/TableHeader';
import TableFooter from './subcomponents/TableFooter';
import TableBody from './subcomponents/TableBody';
import GlobalFilter from './subcomponents/GlobalFilter';

const Table = ({
  columns,
  data,
  shouldEnablePagination,
  shouldEnableGlobalFilter,
  tableContentClassName,
  onRowSelectionChange,
  editableCellHandlers,
  getRowId,
}) => {
  const hasFooter = _.findIndex(columns, (column) => !!column.Footer) !== -1;

  const stateReducer = (newState, action, prevState, instance) => {
    let modifiedSelectedRowIds = {};

    switch (action.type) {
      case ReactTableAction.TOGGLE_ALL_PAGE_ROWS_SELECTED:
      case ReactTableAction.TOGGLE_ALL_ROWS_SELECTED:
        modifiedSelectedRowIds = {};

        Object.keys(newState.selectedRowIds).forEach((rowIndex) => {
          modifiedSelectedRowIds[rowIndex] = !instance.rowsById[rowIndex].original.disableSelection && action.value;
        });

        return { ...newState, selectedRowIds: modifiedSelectedRowIds };
      case ReactTableAction.TOGGLE_ROW_SELECTED:
        modifiedSelectedRowIds = { ...newState.selectedRowIds };
        modifiedSelectedRowIds[action.id] = !instance.rowsById[action.id].original.disableSelection && action.value;

        return { ...newState, selectedRowIds: modifiedSelectedRowIds };
      default:
        return newState;
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,

    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // disable auto resetting
      autoResetPage: false,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      stateReducer,
      // https://github.com/TanStack/table/blob/b77ed829917ab3254be48bff2aac7602b9133982/examples/editable-data/src/App.js#L106
      shouldEnablePagination,
      shouldEnableGlobalFilter,
      disableSortRemove: true,
      getRowId,
      ...editableCellHandlers,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    onRowSelectionChange?.(state.selectedRowIds);
  }, [state.selectedRowIds]); // eslint-disable-line

  const goToLastPage = () => {
    gotoPage(pageCount - 1);
  };

  const goToFirstPage = () => {
    gotoPage(0);
  };

  const updateGlobalFilter = useAsyncDebounce(({ target }) => {
    setGlobalFilter(target.value);
  }, 400);

  return (
    <>
      {shouldEnableGlobalFilter && (
        <div className='sm:flex sm:gap-x-2'>
          <GlobalFilter onChange={updateGlobalFilter} />
        </div>
      )}
      <div className={`${tableContentClassName}`}>
        <table {...getTableProps()} className='relative w-full divide-y divide-gray-200'>
          <TableHeader headerGroups={headerGroups} />
          <TableBody
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            page={page}
            shouldEnablePagination={shouldEnablePagination}
            prepareRow={prepareRow}
          />
          {hasFooter && <TableFooter footerGroups={footerGroups} />}
        </table>
      </div>
      {shouldEnablePagination && (
        <Pagination
          goToNextPage={nextPage}
          goToPreviousPage={previousPage}
          goToLastPage={goToLastPage}
          goToFirstPage={goToFirstPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={state.pageIndex}
          pageOptions={pageOptions}
        />
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  shouldshouldEnablePagination: PropTypes.bool,
  shouldEnableGlobalFilter: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  getRowId: PropTypes.func,
};

export default Table;
