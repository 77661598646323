import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'elements';

const GlobalFilter = ({
  onChange,
}) => {
  return (
    <Input
      type="text"
      onChange={onChange}
      placeholder={`Search Items`}
    />
  )
}

GlobalFilter.propTypes = {
  onChange: PropTypes.func,
}

export default GlobalFilter;