import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { fontSize, loadingIconAnimation as loadingAnimation } from '../utils/theme';

export const modifiers = {
  size16: () => ` span { ${fontSize(16)} }`,
  primaryNoHover: ({ theme }) => ({
    styles: `
      &:hover {
        background-color: ${theme.farmProject.dark_gray};
        border: 1px solid transparent;
        span {
          color: ${theme.farmProject.gray_3};
        }
      }
    `,
  }),
  secondary: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.gray_3};

      span {
        color: ${theme.farmProject.dark_gray};
      }

      &:hover {
      border-color: ${theme.farmProject.light_gray};
      background-color: ${theme.farmProject.white};
        span {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  lined: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.white};
      border: solid 1px ${theme.farmProject.brand_primary};

      span {
        color: ${theme.farmProject.dark_gray};
      }

      &:hover {
      border-color: ${theme.farmProject.light_gray};
      background-color: ${theme.farmProject.light_gray}20;
        span {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  white: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.white};
      border-color: ${theme.farmProject.light_gray}30;

      span {
        color: ${theme.farmProject.dark_gray};
      }

      &:hover {
        border-color: ${theme.farmProject.light_gray}50;
        background-color: ${theme.farmProject.white};
        span {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  whiteActive: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.white};
      border-color: ${theme.farmProject.green};

      span {
        color: ${theme.farmProject.dark_gray};
      }

      &:hover {
        border-color: ${theme.farmProject.light_gray};
        background-color: ${theme.farmProject.white};
        span {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  tertiary: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.brand_primary};
      border: solid 1px ${theme.farmProject.brand_primary};

      span {
        color: ${theme.farmProject.gray_3};
      }

      &:hover {
        background-color: transparent;
        border: solid 1px ${theme.farmProject.brand_primary};

        span {
          color: ${theme.farmProject.gray};
        }
      }
    `,
  }),
  quaternary: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_green};
      border: solid 1px ${theme.farmProject.dark_green};

      span {
        color: ${theme.farmProject.gray_3};
      }

      &:hover {
        background-color: transparent;
        border: solid 1px ${theme.farmProject.dark_green};

        span {
          color: ${theme.farmProject.gray};
        }
      }
    `,
  }),
  inverted: ({ theme }) => ({
    styles: `
      background-color: transparent;
      border-color: ${theme.farmProject.dark_gray};
      span {
        color: ${theme.farmProject.dark_gray};
      }
      &:hover {
        background-color: ${theme.farmProject.dark_gray};
        border-color: transparent;

        span {
          color: ${theme.farmProject.gray_3};
        }
      }
    `,
  }),
  invertedTertiary: ({ theme }) => ({
    styles: `
      background-color: transparent;
      border-color: transparent;
      span {
        color: ${theme.farmProject.dark_gray};
      }
      &:hover {
        border-color: ${theme.farmProject.brand_primary};
      }
    `,
  }),
  invertedNoHover: ({ theme }) => ({
    styles: `
      background-color: transparent;
      border-color: ${theme.farmProject.dark_gray};
      span {
        color: ${theme.farmProject.dark_gray};
      }
      &:hover {
        border-color: ${theme.farmProject.dark_gray};
      }
    `,
  }),
  disabled: () => ({
    styles: `
      opacity: 0.2;
      pointer-events: none;

      &:hover {
        background-color: inherit;
        border: 1px solid transparent;

        span {
          color: inherit;
        }
      }
    `,
  }),
  newDisabled: ({ theme }) => ({
    styles: `
      pointer-events: none;
      border: none;
      background-color: ${theme.farmProject.light_gray};

      span {
        color: ${theme.farmProject.gray_3};
      }
    `,
  }),
  greenDisabled: ({ theme }) => ({
    styles: `
      pointer-events: none;
      border: none;
      background-color: ${theme.farmProject.green_disabled};

      span {
        color: ${theme.farmProject.gray_3};
      }
    `,
  }),
  ghost: ({ theme }) => ({
    styles: `
      pointer-events: none;
      color: ${theme.farmProject.mid_gray};
      border-color: ${theme.farmProject.mid_gray};
      span {
        color: ${theme.farmProject.mid_gray};
      }
    `,
  }),
  fluid: () => ({
    styles: `
    width: 100%;
    `,
  }),
};

export const SquaredButtonStyled = styled.button
  .withConfig({
    shouldForwardProp: (prop) => !['modifiers'].includes(prop),
  })
  .attrs({
    className: 'Button',
  })`
  background-color: ${({ theme }) => theme.farmProject.dark_gray};
  height: 2.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
  border: 1px solid transparent;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 0.125rem;

  span {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.75rem;
    letter-spacing: 0.09375rem;
    color: ${({ theme }) => theme.farmProject.gray_3};
    text-decoration: none;
    white-space: nowrap;
  }

  &:hover {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.farmProject.dark_gray};
    span {
      color: ${({ theme }) => theme.farmProject.dark_gray};
    }
  }

  ${(props) => props.modifiers?.includes?.('loading') && loadingAnimation}

  ${applyStyleModifiers(modifiers)};
`;
