import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';
import { ThemeProvider } from 'styled-components';

import TextField from '@material-ui/core/TextField';
import { theme } from '../utils/theme';

import { InputStyled, modifiers } from './Input.styled';

const Input = ({
  id,
  name,
  value,
  placeholder,
  required,
  onChange,
  onKeyPress,
  onKeyDown,
  onBlur,
  modifiers,
  label,
  type,
  variant,
  error,
  helperText,
  autoComplete,
  autoFocus,
  inputMode,
  pattern,
  dataNw,
  accept,
  disabled,
  endAdornment,
  className,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <InputStyled className={className} modifiers={modifiers}>
        <TextField
          id={id}
          variant={variant}
          type={type}
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onBlur={onBlur}
          required={required}
          error={!!error}
          helperText={error || helperText}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          InputProps={{
            inputMode,
            pattern,
            'data-nw': dataNw,
            accept,
            endAdornment,
            'aria-describedby': error ? ':r5:-helper-text' : null,
          }}
          disabled={disabled}
        />
      </InputStyled>
    </ThemeProvider>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  modifiers: styleModifierPropTypes(modifiers),
  dataNw: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  endAdornment: PropTypes.any,
};

Input.defaultProps = {
  error: null,
  required: false,
  autoFocus: false,
  onKeyPress: null,
  onKeyDown: null,
  disabled: false,
};

export default Input;
export { modifiers };
