import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { fontSize } from '../utils/theme';

export const modifiers = {
  small: () => ({
    styles: `
      span {
        ${fontSize(16)},
      }`,
  }),
  secondary: ({ theme }) => ({
    styles: `
      border-bottom-color: ${theme.farmProject.dark_gray};
      span {
        color: ${theme.farmProject.dark_gray};
        text-decoration-color: ${theme.farmProject.dark_gray};
      }
    `,
  }),
  inverted: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.gray_3};
      span {
        color: ${theme.farmProject.gray_3};
      }
    `,
  }),
  brand: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.brand_primary};
      border-bottom-color: ${theme.farmProject.brand_primary};
      span {
        color: ${theme.farmProject.brand_primary};
        text-decoration-color: ${theme.farmProject.brand_primary};
      }
    `,
  }),
  underline: () => ({
    styles: `
      span {
        text-decoration: underline;
      }
    `,
  }),
  underlineContent: () => ({
    styles: `
      text-decoration: underline;
    `,
  }),
  underlined: () => ({
    styles: `
      border-bottom: 1px solid;
    `,
  }),
  italic: () => ({
    styles: `
      font-style: italic;
    `,
  }),
  fontFamilyText: ({ theme }) => ({
    styles: `
      span {
        font-family: ${theme.farmProject.font_family_text};
      }
    `,
  }),
  compact: () => ({
    styles: `
      padding: 0;
      display: unset;
      span {
        letter-spacing: normal;
      }
    `,
  }),
  bold: () => ({
    styles: `
      font-weight: bold;
    `,
  }),
  brandHover: ({ theme }) => `
    :hover,
    :hover *,
    :focus * {
        color: ${theme.farmProject.brand_primary};
        transition: all 0.2s ease;
      }
  `,
  textLeft: () => ({
    styles: `
      text-align: left;
      span {
        text-align: left;
      }
    `,
  }),
  wFull: () => ({
    styles: `
      width: 100%;
    `,
  }),
};

export const ButtonTextStyled = styled.button
  .withConfig({
    shouldForwardProp: (prop) => !['modifiers'].includes(prop),
  })
  .attrs({
    className: 'ButtonText',
  })`
  background-color: transparent;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border: none;
  margin: 0;
  transition: all 0.125s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
  font-weight: normal;

  span {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.75rem;
    letter-spacing: 0.05375rem;
    color: ${({ theme, disabled }) => (disabled ? theme.farmProject.gray_2 : theme.farmProject.green)};
    text-decoration: none;
  }

  svg {
    width: 0.75rem;
    height: 0.75rem;
    fill: ${({ theme }) => theme.farmProject.green};
    margin-left: 0.25rem;
  }

  &:hover {
  }

  ${applyStyleModifiers(modifiers)};
`;
