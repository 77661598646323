import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';

import { Select, SquaredButton } from 'elements';
import { CentersDropdown, PageHeaderToolbar, Table } from 'components';
import { getSowPeriods } from 'utils/date';
import axiosUtil from 'utils/axios';

import NewSeedlingInventoryModal from './subcomponents/NewSeedlingInventory';
import UploadInventoryModal from './subcomponents/UploadInventory';

const SeedlingInventory = () => {
  const [data, setData] = useState([]);
  const [plantTypes, setPlantTypes] = useState({});
  const [selectedCenter, setSelectedCenter] = useState({});
  const [selectedSowDate, setSelectedSowDate] = useState('');

  const [showNewInventory, setShowNewInventory] = useState(false);
  const [showUploadInventory, setShowUploadInventory] = useState(false);

  const getSeedlingInventory = async (date, center) => {
    const { success } = await axiosUtil.get(
      `/app/protected/seedling/summaryWeek`,
      {
        params: {
          cohortDate: date,
          centerId: center.longId,
          center: center.id,
          active: true,
        },
      }
    );

    if (success) {
      setData(success.data);
    }
  };

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: (row) => plantTypes[row.plantTypeIdString]?.commonName, Footer: <span>Total:</span> },
      {
        Header: 'Purchase Order',
        accessor: 'ordered',
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.ordered + sum, 0)}</>;
        },
      },
      {
        Header: 'Planted',
        accessor: 'planted',
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.planted + sum, 0)}</>;
        },
      },
      {
        Header: 'In',
        accessor: 'in',
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.planted + sum, 0)}</>;
        },
      },
      {
        Header: 'Rollover',
        accessor: 'rollover',
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.rollover + sum, 0)}</>;
        },
      },
      {
        Header: 'Total Inventory',
        id: 'totalInventory',
        accessor: (row) => row.in + row.rollover,
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.totalInventory + sum, 0)}</>;
        },
      },
      {
        Header: 'Customer Order',
        accessor: 'customerOrdered',
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.customerOrdered + sum, 0)}</>;
        },
      },
      {
        Header: 'Fulfilled',
        id: 'fulfilled',
        accessor: 'fulfilled',
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.fulfilled + sum, 0)}</>;
        },
      },
      {
        Header: 'Unfulfilled',
        id: 'unfulfilled',
        accessor: (row) => row.customerOrdered - row.fulfilled,
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.unfulfilled + sum, 0)}</>;
        },
      },
      {
        Header: 'Stock on Hand',
        id: 'stockOnHand',
        accessor: (row) => row.in + row.rollover - row.fulfilled,
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.stockOnHand + sum, 0)}</>;
        },
      },
      {
        Header: 'Unsold Inventory',
        id: 'unsoldInventory',
        accessor: (row) => row.in + row.rollover - row.customerOrdered,
        Footer: (data) => {
          return <>{_.reduce(data.rows, (sum, row) => row.values.unsoldInventory + sum, 0)}</>;
        },
      },
    ],
    [plantTypes]
  );

  useEffect(() => {
    if (selectedSowDate && selectedCenter) {
      getSeedlingInventory(selectedSowDate, selectedCenter);
    }
  }, [selectedSowDate, selectedCenter]);

  useEffect(() => {
    const getPlantTypes = async () => {
      const { success } = await axiosUtil.get(`app/protected/load/grow.db.PlantType`);
      if (success) {
        const plantTypes = Object.fromEntries(
          success.data.map((plant) => {
            return [plant.id, plant];
          })
        );

        setPlantTypes(plantTypes);
      }
    };

    getPlantTypes();
  }, []);

  const handleCenterChange = (e, center) => {
    setSelectedCenter(center);
    setSelectedSowDate('');
  };

  const handleSowDateChange = ({ target }) => {
    const value = target.value;
    setSelectedSowDate(value);
  };

  const isAddNewInventoryDisabled = !selectedCenter || !selectedSowDate;

  return (
    <>
      <div className='flex flex-row gap-2 p-4'>
        <h2>Seedling Inventory Page</h2>
      </div>
      <PageHeaderToolbar
        leftContent={[
          <CentersDropdown value={selectedCenter?.id} onChange={handleCenterChange} seedlingOnly />,
          <Select
            options={getSowPeriods({ weeksBeforeCurrent: 1, weeksAfterCurrent: 7, shouldHaveDefault: true })}
            value={selectedSowDate}
            label='Week'
            onChange={handleSowDateChange}
            disabled={!selectedCenter}
            modifiers={['compact']}
          />,
        ]}
        rightContent={[
          <SquaredButton
            modifiers={['tertiary', isAddNewInventoryDisabled && 'disabled']}
            label='New Inventory Form'
            disabled={isAddNewInventoryDisabled}
            onClick={() => setShowNewInventory(true)}
          />,
          <SquaredButton
            modifiers={['tertiary', isAddNewInventoryDisabled && 'disabled']}
            label='Upload New Inventory'
            disabled={isAddNewInventoryDisabled}
            onClick={() => setShowUploadInventory(true)}
          />,
        ]}
      />
      <Table columns={columns} data={data} tableContentClassName='overflow-x-auto max-h-[90vh]' />

      <NewSeedlingInventoryModal
        initialTableData={data}
        plantTypes={plantTypes}
        selectedCenter={selectedCenter}
        selectedSowDate={selectedSowDate}
        isOpen={showNewInventory}
        center={selectedCenter}
        onClose={() => setShowNewInventory(false)}
        onSuccess={() => {
          getSeedlingInventory(selectedSowDate, selectedCenter);
          setShowNewInventory(false);
        }}
      />
      <UploadInventoryModal
        center={selectedCenter}
        sowDate={selectedSowDate}
        isOpen={showUploadInventory}
        onClose={() => setShowUploadInventory(false)}
        onSuccess={() => {
          getSeedlingInventory(selectedSowDate, selectedCenter);
          setShowUploadInventory(false);
        }}
      />
    </>
  );
};

export default SeedlingInventory;
