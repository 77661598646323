import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { CentersContext } from 'contexts/Centers';
import { Select } from 'elements';

const defaultCenterOption = { label: 'Select a center', value: '' };

const CentersDropdown = ({ seedlingOnly, onChange, value }) => {
  const { centersById } = useContext(CentersContext);

  const handleCenterChange = (e) => {
    const selectedCenter = centersById[e.target.value] || { id: '' };
    onChange(e, selectedCenter);
  };

  const distCenters = useMemo(
    () => [
      defaultCenterOption,
      ...Object.values(centersById)
        .filter((center) => (seedlingOnly ? center.seedling : true))
        .map((center) => ({
          value: center.id,
          label: center.name,
        })),
    ],
    [centersById, seedlingOnly]
  );

  return (
    <Select
      options={distCenters}
      value={value}
      label='Distribution Center'
      onChange={handleCenterChange}
      modifiers={['compact']}
      defaultValue={null}
      className='m-0'
    />
  );
};

CentersDropdown.propTypes = {
  seedlingOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
export default CentersDropdown;
