export const updateTableRow = (payload, rowIdToUpdate, tableData) => {
  const updatedTable = [...tableData];

  updatedTable[rowIdToUpdate] = {
    ...updatedTable[rowIdToUpdate],
    ...payload,
  };

  return updatedTable;
};

export const rowsHasSomeSelectable = (rows) => rows.some((row) => !row.original.disableSelection) && !!rows.length;

export const allSelectableRowsSelected = (rows) => rows.every((row) => row.isSelected || row.original.disableSelection);

export const paginatedIsChecked = (page) => {
  return page.some((row) => !row.original.disableSelection) && page.every((row) => row.original.disableSelection || row.isSelected);
};
export const paginatedIndeterminate = (page) =>
  page.some(({isSelected }) => isSelected) && !paginatedIsChecked(page);


export const isChecked = (rows) => rowsHasSomeSelectable(rows) && allSelectableRowsSelected(rows);

export const isIndeterminate = (rows) =>
  rowsHasSomeSelectable(rows) && !allSelectableRowsSelected(rows) && rows.some((row) => row.isSelected);
