import React from "react";
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { WEEK_STARTING_DAY } from "utils/date";

const TableDatepicker = ({
  row,
  onChange,
  value,
  placeholderText,
  showWeekNumbers
}) => {
  const handleChange = (value) => {
    onChange?.(value, row);
  }

  const dateObj = value !== null && value !== '' ? new Date(value) : null;

  return (
    <div className='flex flex-row justify-start'>
      <div className={showWeekNumbers && 'week-number-datepicker flex grow-0 shrink-0 w-24'}>
        <DatePicker
          onChange={handleChange}
          dateFormat="MM/dd/yyyy"
          calendarStartDay={WEEK_STARTING_DAY}
          placeholderText={placeholderText}
          selected={dateObj}
          showWeekNumbers={showWeekNumbers}
        />
      </div>
    </div>
  )
}

TableDatepicker.propTypes = {
  row: PropTypes.object,
  placeholderText: PropTypes.string,
  onChange: PropTypes.func,
  showWeekNumbers: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default TableDatepicker;