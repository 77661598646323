import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'elements';

const TableNumericInput = ({ row, onBlur, onChange, label, value, disabled, min, max, decimalDigitLimit, error, helperText }) => {
  const validateValue = (value) => {
    const decimal = value.split('.')[1];
    const isDecimalTooLong = decimal?.length > decimalDigitLimit;

    if (isDecimalTooLong || value > max || (min !== undefined && value < min)) {
      return false;
    }
    return true;
  };

  const handleChange = ({ target }) => {
    const isValueValid = validateValue(target.value);
    if (isValueValid) {
      onChange?.(target.value, row);
    }
  };

  const handleBlur = ({ target }) => {
    const isValueValid = validateValue(target.value);
    if (isValueValid) {
      onBlur?.(target.value, row);
    }
  };

  return (
    <Input
      modifiers={['fluid', 'smallMargin', 'clear']}
      type='number'
      className='m-0'
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
};

TableNumericInput.defaultProps = {
  decimalDigitLimit: 2,
};

TableNumericInput.propTypes = {
  row: PropTypes.object,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  decimalDigitLimit: PropTypes.number,
};

export default TableNumericInput;
