import { Menu } from 'antd';
import PropTypes from 'prop-types';

import './menu.less';

const GrowMenu = ({ items, defaultSelectedKeys, mode, className }) => {
  return <Menu mode={mode} items={items} defaultSelectedKeys={defaultSelectedKeys} className={className} />;
};

GrowMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      danger: PropTypes.bool,
      disabled: PropTypes.bool,
      icon: PropTypes.node,
      key: PropTypes.string,
      label: PropTypes.node,
      title: PropTypes.string,
    })
  ),
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
  mode: PropTypes.string,
  className: PropTypes.string,
};

export default GrowMenu;
