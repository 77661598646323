export function setStorage(item, value) {
  return localStorage.setItem(item, value);
}

export function getStorage(item) {
  return localStorage.getItem(item);
}

export function deleteStorage(item) {
  return localStorage.removeItem(item);
}