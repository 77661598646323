import { nextMonday, previousMonday, isMonday, getWeek } from 'date-fns';
import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';

export const WEEK_STARTING_DAY = 1; // Monday

const currentDate = zonedTimeToUtc(new Date(), 'UTC');

export function getDashFormattedDate(date) {
  return formatInTimeZone(date, 'UTC', 'yyyy-MM-dd');
}

export function getSlashFormattedDate(date) {
  return formatInTimeZone(date, 'UTC', 'MM/dd/yyyy');
}

export function getShortMonthDayDate(date) {
  return formatInTimeZone(date, 'UTC', 'MM/dd');
}

export function getHumanFormatDate(date) {
  return formatInTimeZone(date, 'UTC', 'LLLL dd, yyyy');
}

export function getWeekNumber(date) {
  return getWeek(date, WEEK_STARTING_DAY);
}

export const getSowPeriods = ({ weeksBeforeCurrent, weeksAfterCurrent, shouldHaveDefault }) => {
  const currentMonday = isMonday(currentDate) ? currentDate : previousMonday(currentDate);

  const weeks = [];

  // Add the number of previous weeks defined from argument
  let currMonday = currentMonday;
  let prevMonday = '';
  for (let i = 0; i < weeksBeforeCurrent; i++) {
    prevMonday = previousMonday(currMonday);
    weeks.unshift({
      label: `${getSlashFormattedDate(prevMonday)}`,
      value: getDashFormattedDate(prevMonday),
    });

    currMonday = prevMonday;
  }

  // Add current week
  weeks.push({
    label: `${getSlashFormattedDate(currentMonday)}`,
    value: getDashFormattedDate(currentMonday),
  });

  // Add number of weeks after this week as defined by weeksAfterCurrent
  currMonday = currentMonday;
  let mondayAfter = '';
  for (let i = 0; i < weeksAfterCurrent; i++) {
    mondayAfter = nextMonday(currMonday);
    weeks.push({
      label: `${getSlashFormattedDate(mondayAfter)}`,
      value: getDashFormattedDate(mondayAfter),
    });
    currMonday = mondayAfter;
  }

  if (shouldHaveDefault) weeks.unshift({ label: `Select a Sow Date`, value: '' });

  return weeks;
};

export const getUTCTimestamp = (date) => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0)).valueOf();
};

export const getUTCTimestampFromString = (date = null) => {
  return zonedTimeToUtc(new Date(date), 'UTC').valueOf();
};
