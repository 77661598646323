import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';
import { ThemeProvider } from 'styled-components';
import { theme } from '../utils/theme';

import { ButtonTextStyled, modifiers } from './ButtonText.styled';

const ButtonText = ({ id, type, disabled, onClick, label, modifiers, children, as, dataNw, attrs }) => {
  return (
    <ThemeProvider theme={theme}>
      <ButtonTextStyled id={id} type={type} disabled={disabled} onClick={onClick} modifiers={modifiers} as={as} data-nw={dataNw} {...attrs}>
        <span>{label}</span>
        {children}
      </ButtonTextStyled>
    </ThemeProvider>
  );
};

ButtonText.propTypes = {
  id: PropTypes.string,
  as: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node,
  modifiers: styleModifierPropTypes(modifiers),
  dataNw: PropTypes.string,
  attrs: PropTypes.object,
};

ButtonText.defaultProps = {
  disabled: false,
  onClick: null,
};

export default ButtonText;
export { modifiers };
