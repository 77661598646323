import { useState } from 'react';
import { Outlet } from 'react-router';
import CentersContextProvider from 'contexts/Centers';

import AppBar from './AppBar';
import SideNav from './SideNav';

export const HEADER_HEIGHT = 72;

const Navigation = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <CentersContextProvider>
        <div className='flex flex-row'>
          <SideNav showDrawer={showDrawer} setShowDrawer={setShowDrawer} onClose={() => setShowDrawer(false)} />
          <div className='w-full min-w-0'>
            <AppBar
              toggleDrawer={() => {
                setShowDrawer(!showDrawer);
              }}
              showDrawer={showDrawer}
            />
            <div className={`overflow-y-auto bg-gray-200 w-full p-5 h-[calc(100vh-72px)]`}>
              <Outlet />
            </div>
          </div>
        </div>
      </CentersContextProvider>
    </>
  );
};

export default Navigation;
