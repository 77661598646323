import React from 'react';
import { Drawer } from 'antd';

const GrowDrawer = ({ title, width, children, isOpen, onClose, placement }) => {
  return (
    <Drawer
      placement={placement}
      open={isOpen}
      onClose={onClose}
      title={title}
      width={width}
      closable={false}
      bodyStyle={{ padding: 0 }}
      headerStyle={{ padding: 0 }}
    >
      {children}
    </Drawer>
  );
};

export default GrowDrawer;
