import axios from 'axios';
import { toast } from 'react-toastify';

import { getToken } from 'utils/auth';
import { RequestStatus } from 'enums';
import envConfig from 'utils/envConfig';

import { NetworkErrorModal } from 'components';

const axiosInstance = axios.create({
  baseURL: envConfig.gcsUrlPrefix,
});

axiosInstance.interceptors.request.use((config) => {
  let toastId = null;
  if (!config.hideNotification) toastId = toast.loading(`Loading... ${config.url}`);
  return { ...config, toastId };
});

axiosInstance.interceptors.response.use(
  function (axiosResp) {
    const { config } = axiosResp;
    if (!config.hideNotification) {
      toast.update(config.toastId, { render: config.url, type: toast.TYPE.SUCCESS, isLoading: false, autoClose: 2000 });
    }
    return axiosResp;
  },
  (axiosErr) => {
    const { config } = axiosErr;
    if (!config.hideNotification) {
      toast.update(config.toastId, { render: ({ closeToast }) => <NetworkErrorModal axiosErr={axiosErr} closeToast={closeToast} />, type: toast.TYPE.ERROR, isLoading: false, closeButton: true });
    }
    return Promise.reject(axiosErr);
  }
);

export const get = async (url, config) => {
  const result = { success: null, error: null };
  const idToken = await getToken();

  try {
    const response = await axiosInstance.get(url, {
      headers: {
        idToken,
      },
      ...config,
    });

    if (response.status === RequestStatus.OK_REQUEST_STATUS)
      result.success = {
        code: response.status,
        data: response?.data,
      };
  } catch (e) {
    result.error = {
      code: e.code,
      status: e?.response?.status,
      message: e?.response?.message,
    };
  }

  return result;
};

export const post = async (url, body, config) => {
  const result = { success: null, error: null };
  const idToken = await getToken();

  try {
    const response = await axiosInstance.post(url, body, {
      ...config,
      headers: {
        idToken,
      },
    });

    if (response.status === RequestStatus.OK_REQUEST_STATUS)
      result.success = {
        code: response.status,
        data: response?.data,
      };
  } catch (e) {
    result.error = {
      code: e.code,
      status: e?.response?.status,
      message: e?.response?.message || e?.message,
      internalMessage: e?.response?.data?.internalErrorMessage,
    };
  }

  return result;
};

export const put = async (url, body, config) => {
  const result = { success: null, error: null };
  const idToken = await getToken();

  try {
    const response = await axiosInstance.put(url, body, {
      ...config,
      headers: {
        idToken,
      },
    });

    if (response.status === RequestStatus.OK_REQUEST_STATUS)
      result.success = {
        code: response.status,
        data: response?.data,
      };
  } catch (e) {
    result.error = {
      code: e.code,
      status: e?.response?.status,
      message: e?.response?.message || e?.message,
      internalMessage: e?.response?.data?.internalErrorMessage,
    };
  }

  return result;
};

const axiosHelper = {
  get,
  post,
  put,
};

export default axiosHelper;
