import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';

import { SelectStyled } from './Select.styled';
import Icon from '../icon';
import { theme } from '../utils/theme';

/**
 * * Custom Select field - Material UI select does not allow autocomplete, and autocomplete does not allow native mobile options dropdown
 */

const Select = ({
  id,
  className,
  name,
  value,
  required,
  onChange,
  label,
  error,
  options,
  modifiers,
  helperText,
  autoComplete,
  onBlur,
  dataAttributes,
  disabled,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <SelectStyled className={className} $hasValue={!!value} $hasError={!!error} modifiers={modifiers}>
        <Icon>
          <svg xmlns='http://www.w3.org/2000/svg' width='7' height='4' viewBox='0 0 7 4'>
            <path d='M6.643.133c.083-.083.218-.083.3 0 .084.083.084.217.001.3L3.69 3.692c-.083.083-.218.083-.3 0L.132.434C.05.35.05.216.133.133c.083-.083.218-.083.3 0L3.54 3.241 6.643.133z' />
          </svg>
        </Icon>
        <select
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          autoComplete={autoComplete}
          disabled={disabled}
          {...dataAttributes}
        >
          {<option key='default' value='' hidden disabled selected></option>}
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <label htmlFor={name}>{required ? label + '*' : label}</label>
        {(helperText || error) && (
          <FormHelperText error={!!error} required={required}>
            {error || helperText}
          </FormHelperText>
        )}
      </SelectStyled>
    </ThemeProvider>
  );
};

Select.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array,
  autoComplete: PropTypes.string,
  helperText: PropTypes.string,
  dataAttributes: PropTypes.object,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
};

export default Select;
