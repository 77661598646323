import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useQuery } from 'utils/helpers';
import { useAuth } from 'utils/auth';
import { PrivateRoutesWrapper, Loading } from 'components';
import { Login, PlantingReport, TableTest, Profile, SeedlingInventory, DaysToTransplant, RecommendOrderTestReport } from 'pages';
import { Paths } from 'enums';
import { Navigation } from 'components';

function App() {
  const queryParams = useQuery();
  const auth = useAuth();

  useEffect(() => {
    const queryParamUserToken = queryParams.get('userToken');
    if (!auth?.user && queryParamUserToken) {
      auth.getUserInfo(queryParamUserToken);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {auth.isAuthPending ? (
        <div className='bg-growgreen-700'>
          <Loading size='full' />
        </div>
      ) : (
        <Routes>
          <Route path={Paths.TABLE_TEST} element={<TableTest />} />
          <Route path={Paths.LOGIN} element={<Login />} />
          <Route element={<PrivateRoutesWrapper idToken={auth?.user?.idToken} />}>
            <Route element={<Navigation />}>
              <Route path={Paths.HOME} element={<Profile />} />
              <Route path={Paths.PROFILE} element={<Profile />} />
              <Route path={Paths.PLANTING_REPORT} element={<PlantingReport />} />
              <Route path={Paths.SEEDLINGS_INVENTORY} element={<SeedlingInventory />} />
              <Route path={Paths.DTT} element={<DaysToTransplant />} />
              <Route path={Paths.RECOMMENDED_ORDER_TEST_REPORT} element={<RecommendOrderTestReport />} />
            </Route>
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;
