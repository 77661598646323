import React, { useState, useEffect } from 'react';

import SquaredButton from '../atoms/squared-button';

import { Table, PageHeaderToolbar, Loading } from 'components';
import { List, Input } from 'elements';

import axiosUtil from 'utils/axios';
import { getHumanFormatDate } from 'utils/date';

const renderFarmstandItem = (item, idx) => {
  return (
    <li key={idx} className='text-sm text-gray-500 mb-2'>
      {item.commonName}{' '}
      <time dateTime={item.harvestEnd} className='block italic text-xs'>
        Expired Date: {item.harvestEnd}
      </time>
    </li>
  );
};

const renderRecommendedItem = (item, idx) => {
  return (
    <li key={idx} className='text-sm text-gray-500 mb-2'>
      {item.buyable.name}{' '}
      <span className='block italic text-xs'>Reason: {item.reasons.length > 0 ? item.reasons?.[0]?.reason : '---'}</span>
    </li>
  );
};

const columns = [
  {
    Header: 'Customer Name',
    accessor: 'customerName',
  },
  {
    Header: '# of ports',
    accessor: 'numberOfPorts',
  },
  {
    Header: '# of devices',
    accessor: 'numberOfDevices',
  },
  {
    Header: '# of recommended seedlings',
    accessor: 'numberOfRecSeedlings',
  },
  {
    Header: 'Farmstand and Recommendation',
    columns: [
      {
        Header: 'On the Farmstand...',
        accessor: 'farmstand',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => <List values={value} isNumeral={true} renderListItem={renderFarmstandItem} />,
      },
      {
        Header: 'Recommended...',
        accessor: 'recommended',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => <List values={value} isNumeral={true} renderListItem={renderRecommendedItem} />,
      },
    ],
  },
  {
    Header: 'Preferences',
    accessor: 'preferences',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => <List values={value} columns={3} customClass={'w-80'} />,
  },
];

const RecommendOrderTestReport = () => {
  const [tableData, setTableData] = useState([]);
  const [customerID, setCustomerID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [plantTypes, setPlantTypes] = useState({});

  useEffect(() => {
    const getPlantTypes = async () => {
      const { success } = await axiosUtil.get(`app/protected/load/grow.db.PlantType`);
      if (success) {
        const plantTypes = Object.fromEntries(
          success.data.map((plant) => {
            return [plant.id, plant];
          })
        );

        setPlantTypes(plantTypes);
      }
    };

    getPlantTypes();
  }, []);

  const onChangeHandler = (event) => {
    setCustomerID(event.target.value);
  };

  const updateTableData = (data) => {
    setTableData(data);
  };

  const fetchRecommendation = async (mode = 'single') => {
    setIsLoading(true);
    updateTableData([]);

    const initUrl =
      mode === 'single' ? `/app/protected/recommendedOrder/customer/${customerID}` : `/app/protected/recommendedOrder/customer/random`;
    const { success } = await axiosUtil.get(initUrl);

    if (success) {
      const sourceData = mode === 'single' ? [success.data] : success.data;

      let tableData = await Promise.all(
        sourceData.map(async (row) => {
          const endpoints = [
            `/app/protected/customer/${row.customer.idLong}/recommendedOrder/preferences`,
            `/services/myFarm/private/myPlants/customer/${row.customer.idLong}`,
            `/app/protected/device/${row.customer.idString}/list`,
            /*`/services/myFarm/private/myPlants/customer/${row.customer.idLong}/popular`,*/
          ];

          const response = await Promise.all(endpoints.map((endpoint) => axiosUtil.get(endpoint, { hideNotification: true }))).then(
            (values) => values
          );

          const [preferences, farmstandPlants, devices /*, popularPlants*/] = response;

          const updatedFarmstandPlants = farmstandPlants?.success?.data.map((item) => {
            return {
              ...item,
              commonName: plantTypes[item.seedlingSku]?.commonName || item.seedlingSku,
            };
          });

          return {
            customerName: row.customer.name,
            numberOfPorts: row.ports,
            numberOfDevices: devices?.success?.data?.length || 0,
            numberOfRecSeedlings: row.lineItems.reduce((acc, item) => acc + item.count, 0),
            farmstand: updatedFarmstandPlants,
            recommended: row.lineItems,
            preferences: preferences?.success?.data?.buyablePreferences,
          };
        })
      );

      updateTableData(tableData);
    }

    setIsLoading(false);
  };

  return (
    <>
      <h2 className='m-4'>Recommendedation Report Tool</h2>
      <PageHeaderToolbar
        leftContent={[
          <Input
            modifiers={['fluid', 'smallMargin', 'clear']}
            type='number'
            label='Enter Customer ID'
            onChange={onChangeHandler}
            value={customerID}
          />,
        ]}
        rightContent={[
          <div className='flex gap-2 items-center flex-col md:flex-row'>
            <SquaredButton
              modifiers={['secondary', customerID.trim().length !== 0 ? '' : 'disabled']}
              label='SHOW RECOMMENDATION'
              onClick={() => fetchRecommendation()}
            />{' '}
            OR
            <SquaredButton modifiers={['secondary']} label='SHOW 10 RECOMMENDATIONS' onClick={() => fetchRecommendation('random')} />
          </div>,
        ]}
      />

      <h3 className='m-4'>Recommendation Report for {getHumanFormatDate(new Date())}</h3>

      {isLoading ? <Loading /> : <Table columns={columns} data={tableData} tableContentClassName='overflow-x-auto max-h-[90vh]' />}
    </>
  );
};

export default RecommendOrderTestReport;
