import React, { createContext, useEffect, useState } from 'react';
import { BreakPointMediaQuery, BreakPointKeys } from 'enums/BreakPoint';

export const MediaQueryContext = createContext();

const createMatchMedia = (query, onChange) => {
  const media = window.matchMedia(query);

  if (media.matches) {
    onChange(query);
  }

  const listener = (e) => {
    if (e.target.matches) {
      onChange(query);
    }
  };

  media.addEventListener('change', listener);
  media.listener = listener;

  return media;
};

export const MediaQueryContextProvider = ({ children }) => {
  const [matchingQuery, setMatchingQuery] = useState(null);
  /**
    Creates an object with the keys of BreakPoint set to false { 
      XX_LG: false,
      X_LG: false,
      LG: false,
      ...
    }
  */
  const [screenSizes, setScreenSizes] = useState(Object.fromEntries(BreakPointKeys.map((key) => [key, false])));

  useEffect(() => {
    // reference to each matchMedia for listener cleanup
    const mediaMatches = BreakPointKeys.reduce((acc, breakpointKey) => {
      const mediaQuery = BreakPointMediaQuery[breakpointKey];

      acc[breakpointKey] = createMatchMedia(mediaQuery, (query) => {
        // creates a new screenSizes object with the matching query set to true
        setScreenSizes(Object.fromEntries(BreakPointKeys.map((key) => [key, BreakPointMediaQuery[key] === query])));
        setMatchingQuery(query);
      });

      return acc;
    }, {});

    return () => {
      Object.keys(mediaMatches).forEach((key) => {
        mediaMatches[key].removeEventListener('change', mediaMatches[key].listener);
      });
    };
  }, []);

  return <MediaQueryContext.Provider value={{ matchingQuery, screenSizes }}>{children}</MediaQueryContext.Provider>;
};

export default MediaQueryContextProvider;
