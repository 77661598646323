import React from 'react';
import PropTypes from 'prop-types';
import SquaredButton from '../../atoms/squared-button';

const TableSquaredButton = ({ row, onClick, modifiers, label, children, disabled }) => {
  const handleClick = () => {
    onClick?.(row);
  };
  return (
    <SquaredButton onClick={handleClick} modifiers={modifiers} label={label} disabled={disabled}>
      {children}
    </SquaredButton>
  );
};

TableSquaredButton.propTypes = {
  row: PropTypes.object,
  onClick: PropTypes.func,
  label: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  children: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TableSquaredButton;
