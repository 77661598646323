import React, { useState } from 'react';

import SquaredButton from '../atoms/squared-button';

import { Table, PageHeaderToolbar, CentersDropdown } from 'components';
import { TableNumericInput, TableDatepicker, TableSquaredButton, Select, Popover } from 'elements';
import { TableSelectAll, TableCheckbox } from 'components/table/subcomponents';

import { updateTableRow } from 'utils/table';
import { getSlashFormattedDate, getUTCTimestampFromString, getSowPeriods, getDashFormattedDate, getShortMonthDayDate } from 'utils/date';
import { roundToNearest } from 'utils/math';
import axiosUtil from 'utils/axios';
import { exportCSVFile } from 'utils/helpers';

const PLANTING_REPORT_CSV_TITLE = 'Tray Labels';
const PLANTING_REPORT_CSV_FILE_NAME = 'tray_labels';

const columns = [
  {
    id: 'selection',
    Header: (reactTableHeaderProps) => <TableSelectAll {...reactTableHeaderProps} />,
    Cell: ({ row }) => {
      const isDisabled = !getPlannedSowDateValue(row.values.newActualSowDate, row.original.actualSowDate, row.original.recommendedSowDate);
      return <TableCheckbox {...row.getToggleRowSelectedProps()} disabled={isDisabled} />;
    },
    exportIgnore: true,
  },
  {
    Header: 'Name',
    accessor: 'friendlyName',
  },
  {
    Header: 'Recommended Sow Date',
    accessor: 'recommendedSowDate',
  },
  {
    Header: 'Week Due',
    id: 'deliveryDate',
    accessor: (row) => {
      return `${getSlashFormattedDate(row.deliveryDate)}`;
    },
  },
  {
    Header: 'Days to Transplant',
    accessor: 'daysToTransplant',
  },
  {
    Header: 'Tray Demand',
    accessor: 'traysDemanded',
  },
  {
    Header: 'Planned Sow Date',
    accessor: 'newActualSowDate',
    sortType: (rowA, rowB) => {
      const rowAPlannedSowDate = getPlannedSowDateValue(
        rowA.values.newActualSowDate,
        rowA.original.actualSowDate,
        rowA.original.recommendedSowDate
      );
      const rowBPlannedSowDate = getPlannedSowDateValue(
        rowB.values.newActualSowDate,
        rowB.original.actualSowDate,
        rowB.original.recommendedSowDate
      );

      if (rowAPlannedSowDate > rowBPlannedSowDate) {
        return 1;
      }
      if (rowAPlannedSowDate < rowBPlannedSowDate) {
        return -1;
      }
      return 0;
    },
    Cell: ({ row, onDatePickerChange }) => {
      return (
        <TableDatepicker
          row={row}
          onChange={onDatePickerChange}
          placeholderText='Select a Date'
          value={getPlannedSowDateValue(row.values.newActualSowDate, row.original.actualSowDate, row.original.recommendedSowDate)}
          showWeekNumbers
        />
      );
    },
  },
  {
    Header: 'Planned Trays',
    accessor: 'newTrays',
    sortType: (rowA, rowB) => {
      const rowAPlannedTrays = getPlannedTraysValue(rowA.values.newTrays, rowA.original.traysDemanded, rowA.original.traysPlanted);
      const rowBPlannedTrays = getPlannedTraysValue(rowB.values.newTrays, rowB.original.traysDemanded, rowB.original.traysPlanted);

      if (rowAPlannedTrays > rowBPlannedTrays) {
        return 1;
      }
      if (rowAPlannedTrays < rowBPlannedTrays) {
        return -1;
      }
      return 0;
    },
    Cell: ({ row, onTraysPlantedBlur, onTraysPlantedChanged }) => (
      <TableNumericInput
        row={row}
        onBlur={onTraysPlantedBlur}
        onChange={onTraysPlantedChanged}
        label='Planned Trays'
        decimalDigitLimit={1}
        value={getPlannedTraysValue(row.values.newTrays, row.original.traysDemanded, row.original.traysPlanted)}
      />
    ),
  },
  {
    Header: 'Confirmed Trays',
    accessor: (row) => {
      return row.confirmed ? row.traysPlanted : 0;
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    exportIgnore: true,
    Cell: ({ row, onConfirm }) => {
      const readyForConfirm = !row.original.confirmed && row.original.actualSowDate;

      return (
        <>
          <TableSquaredButton
            row={row}
            disabled={!readyForConfirm}
            modifiers={readyForConfirm ? 'primary' : 'white'}
            onClick={onConfirm}
            label='Confirm'
          />
        </>
      );
    },
  },
];

// Logic behind the value to display in the Planned Trays column.
// Trays planted will be 0 until a plan is saved.
// newTrays will be null until a user enters a value.
// We only want to default to trays planted or traysDemanded when a user has not interacted with the field.
const getPlannedTraysValue = (newTrays, traysDemanded, traysPlanted) => {
  return newTrays === null ? traysPlanted || traysDemanded : newTrays;
};

const getPlannedSowDateValue = (newActualSowDate, actualSowDate, recommendedSowDate) =>
  newActualSowDate || actualSowDate || recommendedSowDate;

const PlantingReport = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState('');
  const [selectedSowDate, setSelectedSowDate] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAction, setSelectedAction] = useState('default');

  const handleCenterChange = ({ target }) => {
    setSelectedCenter(target.value);
    setSelectedSowDate('');
  };

  const handleSowDateChange = ({ target }) => {
    const value = target.value && getDashFormattedDate(target.value);

    if (value) {
      setSelectedSowDate(value);
      getSeedlingLifecyclePlan(value);
    } else {
      setSelectedSowDate(null);
    }
  };

  const updateTableData = (data) => {
    setTableData(data);
  };

  const getSeedlingLifecyclePlan = async (sowDate) => {
    const response = await axiosUtil.get(`app/protected/seedling/plan/${selectedCenter}/${sowDate}`);
    if (response.success?.data) {
      const tableData = response.success.data.map((row) => {
        return {
          ...row,
          actualSowDate: row.actualSowDate ? getSlashFormattedDate(new Date(row.actualSowDate)) : null,
          recommendedSowDate: row.recommendedSowDate ? getSlashFormattedDate(new Date(row.recommendedSowDate)) : null,
          ...{
            newActualSowDate: null,
            newTrays: null,
          },
        };
      });
      updateTableData(tableData);
    }
  };

  const onTraysPlantedChanged = (value, row) => updateTableData(updateTableRow({ newTrays: value }, row.id, tableData));

  const onTraysPlantedBlur = (value, row) => {
    const roundedValue = roundToNearest(value, 0.5);
    setTableData(updateTableRow({ newTrays: roundedValue.toString() }, row.id, tableData));
  };

  const onDatePickerChange = (date, row) => {
    const updatedDate = date === '' || date === null ? date : getSlashFormattedDate(date);

    setTableData(updateTableRow({ newActualSowDate: updatedDate }, row.id, tableData));
  };

  const isRowReadyForPlanSave = ({ newTrays, traysDemanded, newActualSowDate, actualSowDate, recommendedSowDate, traysPlanted }) => {
    const numberOfTraysToBeUpdated = getPlannedTraysValue(newTrays, traysDemanded, traysPlanted) - traysPlanted;
    const plannedSowDate = getPlannedSowDateValue(newActualSowDate, actualSowDate, recommendedSowDate);

    return !!numberOfTraysToBeUpdated || (!!plannedSowDate && !!newActualSowDate);
  };

  const onSubmitSeedlingLifecyclePlan = async (reactTableRow) => {
    const createRequestBodyItem = (row) => {
      const sowDate = getPlannedSowDateValue(row.newActualSowDate, row.actualSowDate, row.recommendedSowDate);
      const newTrays = Number(getPlannedTraysValue(row.newTrays, row.traysDemanded, row.traysPlanted)) - Number(row.traysPlanted);
      return {
        plantOrderItemId: row.plantOrderItemIds[0],
        sowDate: getUTCTimestampFromString(sowDate),
        newTrays: newTrays,
      };
    };

    let requestBody = [];
    const id = reactTableRow?.id;
    if (id) {
      const row = tableData[id];
      requestBody = [createRequestBodyItem(row)];
    } else {
      requestBody = tableData.filter(isRowReadyForPlanSave).map(createRequestBodyItem);
    }
    const response = await axiosUtil.post(`app/protected/seedling/planting/${selectedCenter}`, requestBody);

    if (response.success) {
      await getSeedlingLifecyclePlan(selectedSowDate);
    }
  };

  const onConfirm = async (reactTableRow) => {
    let requestBody = [];
    let id = reactTableRow?.id;
    if (id) {
      requestBody = tableData[id].plantOrderItemIds;
    } else {
      requestBody = tableData.map((row) => {
        return row.plantOrderItemIds[0];
      });
    }

    const response = await axiosUtil.put(`app/protected/seedling/plan/${selectedCenter}/confirm`, requestBody);

    if (response.success) {
      await getSeedlingLifecyclePlan(selectedSowDate);
    }
  };

  const WeekTraysMetrics = () => {
    const selectedPlantsToBeSown = selectedRows.reduce((init, rowId) => {
      const row = tableData[rowId];
      return init + Number(row.newTrays);
    }, 0);
    const traysDemanded = tableData.reduce((init, item) => init + item.traysDemanded, 0);
    return (
      <div className='flex flex-col'>
        <p>This Week Trays to Plant: {traysDemanded}</p>
        <p>Selected plants to sow: {selectedPlantsToBeSown}</p>
      </div>
    );
  };

  const onRowSelectionChange = (data) => {
    setSelectedRows(Object.keys(data).filter((rowIndex) => !!data[rowIndex]));
  };

  const generateSelectedRowsCSV = () => {
    const data = selectedRows.map((rowIndex) => {
      const row = tableData[rowIndex];
      const { newActualSowDate, actualSowDate, recommendedSowDate } = row;
      const plannedSowDate = getPlannedSowDateValue(newActualSowDate, actualSowDate, recommendedSowDate);
      const dueDate = `${getShortMonthDayDate(row.deliveryDate)}`;
      const sowDate = `${getShortMonthDayDate(Date.parse(plannedSowDate))}`;

      return [`${row.friendlyName} (${sowDate} --> ${dueDate})`];
    });

    data.unshift([PLANTING_REPORT_CSV_TITLE]);

    return data;
  };

  const exportCSV = () => {
    const data = generateSelectedRowsCSV();

    exportCSVFile(PLANTING_REPORT_CSV_FILE_NAME, { data, addCurrentTimeToName: true });
  };

  const exportAll = () => {
    // Add an extra empty row for padding
    const firstPageData = [...generateSelectedRowsCSV(), []];

    const validColumns = columns.filter((column) => !column.exportIgnore);
    let headers = validColumns.map((column) => column.Header);

    const dataToExport = tableData.map((row, rowId) => {
      return validColumns.map((column) => {
        let valueToExport = '';
        const { id, accessor } = column;
        if (typeof accessor === 'function') {
          valueToExport = accessor(tableData[rowId]);
        } else if (typeof accessor === 'string') {
          valueToExport = tableData[rowId][accessor];
        } else if (id) {
          valueToExport = tableData[rowId][id];
        }
        return valueToExport;
      });
    });

    exportCSVFile(PLANTING_REPORT_CSV_FILE_NAME, { data: [...firstPageData, headers, ...dataToExport], addCurrentTimeToName: true });
  };

  const actionOptions = {
    default: {
      value: 'default',
      label: 'Select an action',
      isActionDisabled: () => true,
      action: () => {},
      actionDisabledMessage: () => {
        return 'Please select an action';
      },
    },
    generateLabels: {
      value: 'generateLabels',
      label: 'Generate labels',
      action: exportCSV,
    },
    exportTable: {
      value: 'exportTable',
      label: 'Export Table',
      action: exportAll,
    },
    savePlan: {
      value: 'savePlan',
      label: 'Save Plan',
      action: onSubmitSeedlingLifecyclePlan,
      actionDisabledMessage: () => {
        if (tableData.length === 0 && (!selectedCenter || !selectedSowDate)) {
          return 'Please select a center and week';
        }

        return null;
      },
    },
    confirmAll: {
      value: 'confirmAll',
      label: 'Confirm All',
      action: onConfirm,
      actionDisabledMessage: () => {
        if (tableData.length === 0 && (!selectedCenter || !selectedSowDate)) {
          return 'Please select a center and week';
        }

        const anyReadyForConfirm = tableData.some((row) => {
          return !row.confirmed && !!row.actualSowDate && !!row.traysPlanted;
        });

        if (!anyReadyForConfirm) {
          return 'Please save a plan before confirming all';
        }

        return null;
      },
    },
  };

  const actionDisabledMessage = actionOptions[selectedAction].actionDisabledMessage?.();

  return (
    <>
      <div className='flex flex-row gap-2 p-4'>
        <h2>Planting Report</h2>
      </div>
      <PageHeaderToolbar
        leftContent={[
          <CentersDropdown value={selectedCenter} onChange={handleCenterChange} seedlingOnly />,
          <Select
            options={getSowPeriods({ weeksBeforeCurrent: 4, weeksAfterCurrent: 5, shouldHaveDefault: true })}
            value={selectedSowDate}
            label='Week'
            onChange={handleSowDateChange}
            disabled={!selectedCenter}
            modifiers={['compact']}
            defaultValue={null}
            className='m-0'
          />,
        ]}
        rightContent={[
          <WeekTraysMetrics />,
          <div className='flex gap-2'>
            <Select
              options={Object.values(actionOptions)}
              onChange={(e) => setSelectedAction(e.target.value)}
              modifiers={['compact']}
              value={selectedAction}
              className='m-0'
            />
            <Popover title={actionDisabledMessage}>
              <SquaredButton
                modifiers={!!actionDisabledMessage ? 'white' : 'tertiary'}
                label='GO'
                disabled={!!actionDisabledMessage}
                onClick={() => {
                  actionOptions[selectedAction].action();
                }}
              />
            </Popover>
          </div>,
        ]}
      />
      <Table
        columns={columns}
        data={tableData}
        editableCellHandlers={{
          onDatePickerChange,
          onTraysPlantedBlur,
          onSubmitSeedlingLifecyclePlan,
          onTraysPlantedChanged,
          onConfirm,
        }}
        onRowSelectionChange={onRowSelectionChange}
        tableContentClassName='overflow-x-auto max-h-[90vh]'
      />
    </>
  );
};

export default PlantingReport;
