import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import reportWebVitals from './reportWebVitals';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/index.less';
import App from './App';
import { ProvideAuth } from 'utils/auth';
import { MediaQueryContextProvider } from 'contexts/MediaQuery';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import envConfig from 'utils/envConfig';

const container = document.getElementById('root');
const root = createRoot(container);
export const MediaQueryContext = React.createContext();

if (envConfig.environment === 'production') {
  LogRocket.init('yibjp2/gcs-v2');
  // after calling LogRocket.init()
  setupLogRocketReact(LogRocket);
}

root.render(
  <MediaQueryContextProvider>
    <ProvideAuth>
      <BrowserRouter>
        <App />
        <ToastContainer transition={Slide} position={toast.POSITION.BOTTOM_LEFT} />
      </BrowserRouter>
    </ProvideAuth>
  </MediaQueryContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
