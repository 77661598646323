import React, { useState, useEffect, useContext, createContext } from 'react';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, signInWithPopup, getAuth, signOut } from 'firebase/auth';

import axiosInstance from 'utils/axios';
import { setStorage, getStorage, deleteStorage } from './storageManager';
import envConfig from 'utils/envConfig';

const firebaseConfig = {
  apiKey: envConfig.firebaseApiKey,
  authDomain: envConfig.firebaseAuthDomain,
  databaseURL: envConfig.firebaseDbURl,
  projectId: envConfig.firebaseProjectId,
  storageBucket: envConfig.firebaseStorageBucket,
  messagingSenderId: envConfig.firebaseMessagingSenderId,
};

export const auth = getAuth(initializeApp(firebaseConfig));
export const googleLoginProvider = new GoogleAuthProvider();
const authContext = createContext();

export async function getToken() {
  return (await auth.currentUser?.getIdToken()) || getStorage('idToken');
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isAuthPending, setIsAuthPending] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((fbUser) => {
      if (fbUser && fbUser?.accessToken !== user?.idToken) {
        setStorage('idToken', fbUser.accessToken);
        setUser({ ...user, idToken: fbUser.accessToken });
        setIsAuthPending(false);
      }
    });

    const authStateChangedUnsubscribe = auth.onAuthStateChanged((fbUser) => {
      if (fbUser) {
        getUserInfo();
      } else {
        setUser(null);
        deleteStorage('idToken');
        setIsAuthPending(false);
      }
    });
    return () => {
      unsubscribe();
      authStateChangedUnsubscribe();
    };
  }, []); // eslint-disable-line

  async function loginWithGoogle() {
    setIsAuthPending(true);
    try {
      await signInWithPopup(auth, googleLoginProvider);
    } catch (e) {
      console.log(e);
    }
    setIsAuthPending(false);
  }

  async function logout() {
    setIsAuthPending(true);
    try {
      await signOut(auth);
    } catch (e) {
      console.log(e);
    }
    setIsAuthPending(false);
  }

  // Allow idToken as an argument for query param "login"
  async function getUserInfo(idToken) {
    const token = idToken || (await getToken());

    const config = idToken
      ? {
          headers: {
            idToken: token,
          },
        }
      : {};

    const { success } = await axiosInstance.get('/app/protected/myInfo', config);

    if (success?.data?.id && success?.data?.active) {
      setUser({ ...success.data, idToken: token });
    } else {
      setUser(null);
    }
  }

  return {
    user,
    isAuthPending,
    loginWithGoogle,
    logout,
    getUserInfo,
  };
}
