import React from 'react';
import PropTypes from 'prop-types';

import BitSelect from '../../atoms/select';

const Select = ({
  id,
  name,
  value,
  required,
  onChange,
  label,
  error,
  options,
  modifiers,
  helperText,
  autoComplete,
  onBlur,
  dataAttributes,
  disabled,
  className,
}) => {
  return (
    <BitSelect
      id={id}
      name={name}
      className={className}
      value={value}
      required={required}
      onChange={onChange}
      label={label}
      error={error}
      options={options}
      modifiers={modifiers}
      helperText={helperText}
      autoComplete={autoComplete}
      onBlur={onBlur}
      dataAttributes={dataAttributes}
      disabled={disabled}
    />
  );
};

Select.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })).isRequired,
  autoComplete: PropTypes.string,
  helperText: PropTypes.string,
  dataAttributes: PropTypes.object,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
};

export default Select;
