import React from 'react';
import PropTypes from 'prop-types';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

const TableHeader = ({ headerGroups }) => {
  return (
    <thead className='bg-gray-50 sticky top-0 z-30'>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              scope='col'
              className='px-2 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase group'
            >
              <div className='flex items-center justify-between'>
                {column.render('Header')}
                {!column.disableSortBy && <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <UpOutlined className='w-4 h-4 text-gray-400' />
                    ) : (
                      <DownOutlined className='w-4 h-4 text-gray-400' />
                    )
                  ) : (
                    <DownOutlined className='w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100' />
                  )}
                </span>}
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

TableHeader.propTypes = {
  headerGroups: PropTypes.array,
};

export default TableHeader;
