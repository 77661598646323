export const BreakPoint = {
  XX_LG: 1536,
  X_LG: 1280,
  LG: 1024,
  MD: 768,
  SM: 640,
  TINY: 320,
};

export const BreakPointAll = Object.values(BreakPoint);
export const BreakPointKeys = Object.keys(BreakPoint);

export const BreakPointMediaQuery = {
  XX_LG: `(min-width: ${BreakPoint.X_LG - 1}px)`,
  X_LG: `(max-width: ${BreakPoint.X_LG}px) and (min-width: ${BreakPoint.LG - 1}px)`,
  LG: `(max-width: ${BreakPoint.LG}px) and (min-width: ${BreakPoint.MD - 1}px)`,
  MD: `(max-width: ${BreakPoint.MD}px) and (min-width: ${BreakPoint.SM - 1}px)`,
  SM: `(max-width: ${BreakPoint.SM}px) and (min-width: ${BreakPoint.TINY - 1}px)`,
  TINY: `(max-width: ${BreakPoint.TINY}px)`,
};

export const BreakPointMediaQueryAll = Object.values(BreakPointMediaQuery);
export const BreakPointMediaQueryKeys = Object.keys(BreakPointMediaQuery);


export default BreakPoint;
