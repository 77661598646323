import { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { Drawer, Menu } from 'elements';
import { DrawerPlacement } from 'elements/drawer/enums';
import { Paths } from 'enums';
import { MediaQueryContext } from 'contexts/MediaQuery';

import LGLogo from '../../assets/css/icon.svg';

import { MdOutlineGrass, MdOutlineReorder } from 'react-icons/md';
const { Sider } = Layout;

const SIDE_BAR_WIDTH = 250;

const routes = {
  plants: [
    { label: 'Seedling Inventory ', path: Paths.SEEDLINGS_INVENTORY },
    { label: 'Planting Report ', path: Paths.PLANTING_REPORT },
    { label: 'Days to Transplant ', path: Paths.DTT },
  ],
  reports: [
    { label: 'Rec Order Test Report ', path: Paths.RECOMMENDED_ORDER_TEST_REPORT },
  ]
};

const LogoIcon = () => {
  return (
    <div className='w-[45px] shrink-0'>
      <div className='flex items-center content-center justify-center h-full'>
        <img src={LGLogo} className='App-logo bg-growgreen-700 rounded-full' alt='logo' />
      </div>
    </div>
  );
};

const NavType = {
  SIDEBAR: ({ isDrawerOpen, navigate, routes, path }) => {
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={!isDrawerOpen}
        width={SIDE_BAR_WIDTH}
        className='bg-white'
      >
        <div className='p-5 flex align-center overflow-x-hidden'>
          <LogoIcon />
          {<h2 className='font-bold pl-5'>Grow Central Station</h2>}
        </div>
        <Menu
          mode='inline'
          items={[
            {
              label: 'Plants',
              key: 'plants',
              icon: <MdOutlineGrass fontSize='large' />,
              children: routes.plants.map((route, idx) => ({
                key: route.path,
                label: route.label,
                onClick: () => navigate(route.path),
              })),
            },
            {
              label: 'Reports',
              key: 'reports',
              icon: <MdOutlineReorder fontSize='large' />,
              children: routes.reports.map((route, idx) => ({
                key: route.path,
                label: route.label,
                onClick: () => navigate(route.path),
              })),
            },
          ]}
          defaultSelectedKeys={[path]}
        />
      </Sider>
    );
  },
  COMPACT: ({ showDrawer, onClose, navigate, path }) => (
    <Drawer
      isOpen={showDrawer}
      onClose={onClose}
      placement={DrawerPlacement.LEFT}
      title={
        <div className='p-5 flex align-center items-center'>
          <LogoIcon />
          {<h2 className='font-bold pl-5'>Grow Central Station</h2>}
        </div>
      }
      width={SIDE_BAR_WIDTH}
    >
      <Menu
        mode='inline'
        items={[
          {
            label: 'Plants',
            key: 'plants',
            icon: <MdOutlineGrass />,
            children: routes.plants.map((route, idx) => ({
              key: route.path,
              label: route.label,
              onClick: () => navigate(route.path),
            })),
          },
          {
            label: 'Reports',
            key: 'reports',
            icon: <MdOutlineReorder />,
            children: routes.reports.map((route, idx) => ({
              key: route.path,
              label: route.label,
              onClick: () => navigate(route.path),
            })),
          },
        ]}
        defaultSelectedKeys={[path]}
      />
    </Drawer>
  ),
};

const SideNav = ({ setShowDrawer, showDrawer, onClose }) => {
  const { screenSizes } = useContext(MediaQueryContext);
  const { XX_LG, X_LG } = screenSizes;
  const location = useLocation();

  useEffect(() => {
    setShowDrawer(XX_LG);
  }, [XX_LG, setShowDrawer]);

  const navigate = useNavigate();

  return (
    <>
      {(XX_LG || X_LG) && NavType.SIDEBAR({ routes, isDrawerOpen: showDrawer, navigate, path: location.pathname })}
      {!XX_LG && !X_LG && NavType.COMPACT({ routes, showDrawer, onClose, navigate, path: location.pathname })}
    </>
  );
};

export default SideNav;
