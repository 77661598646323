import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Modal, ButtonText, UploadButton } from 'elements';
import { ModalSize } from 'enums';

import axiosUtil from 'utils/axios';
import envConfig from 'utils/envConfig';

const DownloadTemplateButton = ({ center, sowDate }) => {
  const [templateUrl, setTemplateUrl] = useState('');
  const anchorRef = useRef(null);

  useEffect(() => {
    const downloadTemplate = async () => {
      const { success } = await axiosUtil.get(
        `${envConfig.gcsUrlPrefix}/app/protected/seedling/csvTemplate`,
        {
          params: {
            cohortDate: sowDate,
            centerId: center.longId,
          }
        }
      );

      const fileToDownload = new File([success.data], '', { type: 'text/csv' });
      const fileUrl = window.URL.createObjectURL(fileToDownload);

      setTemplateUrl(fileUrl);
    };

    downloadTemplate();

    return () => {
      URL.revokeObjectURL(templateUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, sowDate]);

  const downloadTemplate = () => {
    anchorRef.current.click();
  };

  return (
    <ButtonText variant='contained' modifiers='tertiary' label='Download Template' onClick={downloadTemplate}>
      <a ref={anchorRef} href={`${templateUrl}`} download='Inventory_Template'>
        {''}
      </a>
    </ButtonText>
  );
};

DownloadTemplateButton.propTypes = {
  center: PropTypes.shape({
    longId: PropTypes.number,
  }),
  sowDate: PropTypes.string,
};

const UploadInventoryButton = ({ onClick, onSuccess, onError, center, sowDate, }) => {
  const isDisabled = !center || !sowDate;

  const onUpload = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const { success, error } = await axiosUtil.post(`/app/protected/seedling/csvInventoryUpload/${center.longId}/${sowDate}`, formData, config);

    if (success) {
      onSuccess?.(success);
    }

    if (error) {
      onError?.(error);
    }
  };

  return (
    <UploadButton
      label='Upload Inventory CSV'
      onClick={onClick}
      onUpload={onUpload}
      disabled={isDisabled}
      modifiers={['tertiary', isDisabled && 'disabled']}
    />
  );
};

UploadInventoryButton.propTypes = {
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  center: PropTypes.shape({
    longId: PropTypes.string,
  }),
  sowDate: PropTypes.string,
};

const NewSeedlingInventory = ({ center, sowDate, isOpen, onClose, onSuccess, }) => {
  const [errorMsg, setErrorMsg] = useState('');

  const handleOnClose = () => {
    setErrorMsg('');
    onClose?.();
  };

  const handleError = (error) => {
    setErrorMsg(error.internalMessage);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        size={ModalSize.EXTRA_SMALL}
        onClose={handleOnClose}
        header={
          <>
            <div className='flex justify-center'>{`Upload a CSV of Inventory`}</div>
            <div className='flex justify-center'>{`Center: ${center.name}`}</div>
            <div className='flex justify-center'>{`Week: ${sowDate}`}</div>
          </>
        }
        content={
          <>
            <span className='text-red-500'>{errorMsg}</span>
          </>
        }
        actions={[
          <div className='flex justify-end gap-4'>
            <DownloadTemplateButton center={center} sowDate={sowDate} />
            <UploadInventoryButton
              modifiers='tertiary'
              label='Upload'
              center={center}
              sowDate={sowDate}
              onSuccess={onSuccess}
              onError={handleError}
            />
          </div>,
        ]}
      />
    </>
  );
};

NewSeedlingInventory.propTypes = {
  center: PropTypes.shape({
    longId: PropTypes.number,
  }),
  sowDate: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default NewSeedlingInventory;
