export const Paths = {
  HOME: '/',
  LOGIN: '/login',
  PROFILE: '/profile',
  PLANTING_REPORT: '/planting-report',
  SEEDLINGS_INVENTORY: '/seedlings-inventory',
  TABLE_TEST: '/table-test',
  DTT: '/dtt',
  RECOMMENDED_ORDER_TEST_REPORT: '/recommended-order-test-report',
};

export default Paths;