import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import Input, { modifiers } from '../../atoms/input';

const GrowInput = ({
  id,
  className,
  name,
  value,
  placeholder,
  required,
  onChange,
  onKeyPress,
  onKeyDown,
  onBlur,
  modifiers,
  label,
  type,
  variant,
  error,
  helperText,
  autoComplete,
  autoFocus,
  inputMode,
  pattern,
  dataNw,
  accept,
  disabled,
  endAdornment,
}) => {
  return (
    <Input
      id={id}
      className={className}
      name={name}
      value={value}
      placeholder={placeholder}
      required={required}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      modifiers={modifiers}
      label={label}
      type={type}
      variant={variant}
      error={error}
      helperText={helperText}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      inputMode={inputMode}
      pattern={pattern}
      dataNw={dataNw}
      accept={accept}
      disabled={disabled}
      endAdornment={endAdornment}
    />
  );
};

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  modifiers: styleModifierPropTypes(modifiers),
  dataNw: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  endAdornment: PropTypes.any,
};

Input.defaultProps = {
  error: null,
  required: false,
  autoFocus: false,
  onKeyPress: null,
  onKeyDown: null,
  disabled: false,
};

export default GrowInput;
