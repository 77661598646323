import PropTypes from 'prop-types';

import { useAuth } from 'utils/auth';
import { SquaredButton } from 'elements';

import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined } from '@ant-design/icons';

const MenuAppBar = ({ toggleDrawer, showDrawer }) => {
  const auth = useAuth();

  return (
    <div className='flex justify-between w-full p-5'>
      <SquaredButton modifiers='tertiary' type='primary' onClick={toggleDrawer}>
        {showDrawer ? <MenuFoldOutlined className='text-base' /> : <MenuUnfoldOutlined className='text-base' />}
      </SquaredButton>
      <SquaredButton modifiers='tertiary' type='primary' onClick={auth.logout}>
        <span className='flex justify-center items-center gap-2'>
          <LogoutOutlined />
          Logout
        </span>
      </SquaredButton>
    </div>
  );
};

MenuAppBar.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool.isRequired,
};

export default MenuAppBar;
