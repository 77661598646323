import React from 'react';
import PropTypes from 'prop-types';

const TableFooter = ({ footerGroups }) => {
  return (
    <tfoot className='bg-gray-50'>
      {footerGroups.map((group) => (
        <tr
          className='sticky top-0 z-30 px-2 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase group h-[2rem]'
          {...group.getFooterGroupProps()}
        >
          {group.headers.map((column) => (
            <td className='px-2 whitespace-nowrap text-sm text-gray-500' {...column.getFooterProps()}>
              {column.render('Footer')}
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};

TableFooter.propTypes = {
  footerGroups: PropTypes.array,
};

export default TableFooter;
