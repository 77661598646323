import React, { createContext, useEffect, useState } from 'react';
import axiosUtil from 'utils/axios';

export const CentersContext = createContext();

export const CentersContextProvider = ({ children }) => {
  const [centersById, setCentersById] = useState({});

  useEffect(() => {
    const getDistributionCenters = async () => {
      const { success } = await axiosUtil.get(`app/loadCenters?active=true`);
      if (success.data) {
        const centersById = Object.fromEntries(success.data.map((center) => [center.id, center]));
        setCentersById(centersById);
      }
    };

    getDistributionCenters();
  }, []);

  return <CentersContext.Provider value={{ centersById }}>{children}</CentersContext.Provider>;
};

export default CentersContextProvider;
