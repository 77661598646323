import React from 'react';
import PropTypes from 'prop-types';
import TableCheckbox from './TableCheckbox';
import { paginatedIsChecked, paginatedIndeterminate, isChecked, isIndeterminate } from 'utils/table';

const TableSelectAll = ({
  shouldEnablePagination,
  getToggleAllPageRowsSelectedProps,
  getToggleAllRowsSelectedProps,
  rows,
  page,
}) => {
  const propsCallback = shouldEnablePagination ? getToggleAllPageRowsSelectedProps : getToggleAllRowsSelectedProps;
  const checked = shouldEnablePagination ? paginatedIsChecked(page) : isChecked(rows);

  const indeterminate = shouldEnablePagination ? paginatedIndeterminate(page) : isIndeterminate(rows);

  const reactTableProps = {
    ...propsCallback(),
    checked,
    indeterminate,
  };

  return <TableCheckbox {...reactTableProps} />;
};

TableSelectAll.propTypes = {
  shouldEnablePagination: PropTypes.bool,
  getToggleAllPageRowsSelectedProps: PropTypes.func.isRequired,
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
  rows: PropTypes.array,
  page: PropTypes.array,
};

export default TableSelectAll;
