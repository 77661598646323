import { Tooltip } from 'antd';
import React from 'react';

const GrowPopover = ({ title, children }) => (
  <Tooltip title={title}>
    <div>

    {children}
    </div>
  </Tooltip>
);

export default GrowPopover;