import React from 'react';
import PropTypes from 'prop-types';

const TableBody = ({ getTableBodyProps, rows, page, shouldEnablePagination, prepareRow }) => {
  const rowsToRender = shouldEnablePagination ? page : rows;

  return (
    <tbody {...getTableBodyProps()} className='bg-white divide-y divide-gray-200 w-full'>
      {rowsToRender.map((row, i) => {
        prepareRow(row);
        return (
          <tr className='h-[2rem]' {...row.getRowProps()} key={i}>
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps()} className='p-2 whitespace-nowrap align-top' role='cell'>
                  {cell.column.Cell.name === 'defaultRenderer' ? (
                    <div className='text-sm text-gray-500'>{cell.render('Cell')}</div>
                  ) : (
                    cell.render('Cell')
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

TableBody.propTypes = {
  shouldEnablePagination: PropTypes.bool,
  prepareRow: PropTypes.func,
  getTableBodyProps: PropTypes.func,
  rows: PropTypes.array,
  page: PropTypes.array,
};

export default TableBody;
