import React, { useState } from 'react';

import { Modal } from 'elements';
import { ModalSize } from 'enums';

const NetworkErrorModal = ({ axiosErr, closeToast }) => {
  const { config } = axiosErr;
  const { data, method, url } = config;
  const [isOpen, setOpen] = useState(false);
  const handleOnClose = () => {
    closeToast();
    setOpen(false);
  };

  const handleOnOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className='cursor-pointer' onClick={handleOnOpen}>
        {url}
      </div>
      <Modal
        isOpen={isOpen}
        size={ModalSize.SMALL}
        onClose={handleOnClose}
        header={
          <>
            <div className='flex justify-center'>{`Network Error Details`}</div>
          </>
        }
        content={
          <div>
            <div>
              <strong>URL: </strong>
              {config.url}
            </div>
            <div>
              <strong>Method: </strong>
              {method}
            </div>
            <div>
              <strong>Data: </strong>
              {data}
            </div>
            <div>
              <strong>Message: </strong>
              {axiosErr.message}
            </div>
          </div>
        }
        actions={null}
      />
    </>
  );
};

export default NetworkErrorModal;
