import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { Paths } from 'enums';

const PrivateRoutesWrapper = ({ idToken }) => {
  return idToken ? <Outlet /> : <Navigate to={Paths.LOGIN} />;
}

PrivateRoutesWrapper.propTypes = {
  idToken: PropTypes.string,
}

export default PrivateRoutesWrapper;