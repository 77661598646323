import React, { useMemo, useState, useEffect } from 'react';

import { Table } from 'components';
import { TableNumericInput, Modal, Select, SquaredButton } from 'elements';
import axiosUtil from 'utils/axios';
import { updateTableRow } from 'utils/table';
import { roundToNearest } from 'utils/math';
import { ModalSize } from 'enums';

import { NEW_SEEDLING_INVENTORY_URL } from '../constants';
import { createPlantTypeOptions } from '../utils';

const NewSeedlingInventory = ({ initialTableData = [], plantTypes, center, selectedSowDate, isOpen, onClose, onSuccess }) => {
  const [tableData, setTableData] = useState(initialTableData);
  const [isAddPlantOpen, setIsAddPlantOpen] = useState(false);
  const [plantToAdd, setPlantToAdd] = useState('');

  useEffect(() => setTableData(initialTableData), [initialTableData]);

  const addPlantRow = () => {
    const updatedTabled = [...tableData];
    const plantType = plantTypes[plantToAdd];

    updatedTabled.push({
      plantTypeId: plantType.longId,
      plantTypeIdString: plantType.id,
      newTrays: '',
      avgPlantsPerTray: '',
      rollover: 0,
      customerOrdered: 0,
      fulfilled: 0,
      in: 0,
      ordered: 0,
      planted: 0,
      rolloverOut: 0,
    });

    setTableData(updatedTabled);
  };

  const onAddPlantModalClick = () => {
    addPlantRow();
    setIsAddPlantOpen(false);
  };

  const onPlantSelectChange = (e) => {
    setPlantToAdd(e.target.value);
  };

  const onAddPlantModalOpen = () => {
    setIsAddPlantOpen(true);
  };

  const onAddPlantModalClose = () => {
    setIsAddPlantOpen(false);
  };

  const getRowsToAdd = (tableData) => {
    return tableData.filter((row) => row.newRollover || (row.newTrays && row.avgPerTray));
  };

  const sumbitNewInventory = async () => {
    const rowsToAdd = getRowsToAdd(tableData)
      .filter((row) => row.newRollover || (row.newTrays && row.avgPerTray))
      .map((row) => {
        return {
          plantTypeId: row.plantTypeId,
          centerId: center.longId,
          cohortDate: selectedSowDate,
          newTrays: row.newTrays && row.avgPerTray && +row.newTrays,
          avgPlantsPerTray: row.newTrays && row.avgPerTray && +row.avgPerTray,
          rollover: row.newRollover && +row.newRollover,
        };
      });

    const { success } = await axiosUtil.post(NEW_SEEDLING_INVENTORY_URL, rowsToAdd);

    if (success) {
      onSuccess();
    }
  };

  const onNewStraysChanged = (value, row) => {
    setTableData(updateTableRow({ newTrays: value }, row.id, tableData));
  };

  const onNewStraysBlurred = (value, row) => {
    const roundedValue = roundToNearest(value, 0.5);
    setTableData(updateTableRow({ newTrays: roundedValue }, row.id, tableData));
  };

  const onAvgPerTrayChanged = (value, row) => {
    setTableData(updateTableRow({ avgPerTray: value }, row.id, tableData));
  };

  const onRolloverChanged = (value, row) => {
    setTableData(updateTableRow({ newRollover: value }, row.id, tableData));
  };

  const handleOnClose = () => {
    setTableData(initialTableData);
    onClose();
  };

  const columns = useMemo(
    () => [
      { Header: 'Variety', accessor: (row) => plantTypes[row.plantTypeIdString]?.friendlyName },
      {
        Header: 'Trays Planted',
        accessor: 'planted',
      },
      {
        Header: 'Add trays',
        accessor: 'newTrays',
        Cell: ({ row, onNewStraysChanged, onNewStraysBlurred }) => {
          return (
            <TableNumericInput
              row={row}
              onBlur={onNewStraysBlurred}
              onChange={onNewStraysChanged}
              label='New Trays'
              step={0.5}
              value={row.values?.newTrays}
              error={!row.values?.newTrays && !!row.values?.avgPerTray}
            />
          );
        },
      },
      {
        Header: 'Avg. Plants/Tray',
        accessor: 'avgPerTray',
        Cell: ({ row, onAvgPerTrayChanged }) => (
          <TableNumericInput
            row={row}
            min={0}
            onBlur={onAvgPerTrayChanged}
            onChange={onAvgPerTrayChanged}
            label='Average Plants/Tray'
            value={row.values.avgPerTray}
            error={!!row.values?.newTrays && !row.values?.avgPerTray}
          />
        ),
      },
      {
        Header: 'Total New',
        id: 'totalNew',
        accessor: (row) => row.avgPerTray && row.newTrays && Math.floor(+row.newTrays * +row.avgPerTray),
      },
      {
        Header: 'Rollover',
        accessor: 'newRollover',
        Cell: ({ row, onRolloverChanged }) => (
          <TableNumericInput
            row={row}
            onBlur={onRolloverChanged}
            onChange={onRolloverChanged}
            label='New Rollover'
            decimalDigitLimit={0}
            value={row.original.newRollover}
          />
        ),
      },
      {
        Header: 'Total Plants',
        id: 'totalPlants',
        Cell: ({ row }) => {
          const totalNew = row.values.totalNew ? +row.values.totalNew : 0;
          const newRollover = row.values.newRollover ? +row.values.newRollover : 0;
          return <>{totalNew + newRollover}</>;
        },
      },
    ],
    [plantTypes]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        size={ModalSize.EXTRA_LARGE}
        onClose={handleOnClose}
        header={
          <>
            <div className='flex justify-center'>{`Available Inventory for: ${selectedSowDate}`}</div>
            <div className='flex justify-center'>{center.name}</div>
          </>
        }
        content={
          <Table
            columns={columns}
            tableContentClassName='max-h-[75vh] overflow-scroll'
            data={tableData}
            editableCellHandlers={{
              onNewStraysChanged,
              onAvgPerTrayChanged,
              onRolloverChanged,
              onNewStraysBlurred,
            }}
          />
        }
        actions={
          <div className="flex justify-end gap-4">
            <SquaredButton modifiers='tertiary' label='Add New Plant' onClick={onAddPlantModalOpen} />
            <SquaredButton
              modifiers={['tertiary', !getRowsToAdd(tableData).length && 'disabled']}
              label='Submit'
              onClick={sumbitNewInventory}
            />
          </div>
        }
      />
      <Modal
        isOpen={isAddPlantOpen}
        size={ModalSize.EXTRA_SMALL}
        onClose={onAddPlantModalClose}
        header={<span className='flex justify-center'>Add New Plant</span>}
        content={<Select onChange={onPlantSelectChange} modifiers={['compact']} options={createPlantTypeOptions(plantTypes, tableData)} />}
        actions={
          [
            <SquaredButton modifiers='tertiary' label='Add' onClick={onAddPlantModalClick} />
          ]
        }
      />
    </>
  );
};

export default NewSeedlingInventory;
