import React from 'react';
import PropTypes from 'prop-types';

import BitSquaredButton from '../../atoms/squared-button';

const SquaredButton = ({
  id,
  type,
  disabled,
  onClick,
  label,
  modifiers,
  as,
  target,
  className,
  dataNw,
  ariaLabel,
  ariaSelected,
  children,
}) => {
  return (
    <BitSquaredButton
      id={id}
      type={type}
      disabled={disabled}
      onClick={onClick}
      label={label}
      modifiers={modifiers}
      as={as}
      target={target}
      className={className}
      dataNw={dataNw}
      ariaLabel={ariaLabel}
      ariaSelected={ariaSelected}
      children={children}
    />
  );
};

SquaredButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  modifiers: PropTypes.any,
  as: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  dataNw: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaSelected: PropTypes.string,
  children: PropTypes.node,
};

export default SquaredButton;
