import React from 'react';
import PropTypes from 'prop-types';

const PageHeaderToolbar = ({ leftContent, rightContent }) => {
  return (
    <div className='flex flex-row flex-wrap w-full p-6 align-middle border-b-2 border-gray-400'>
      <div className='flex flex-wrap items-center gap-3 pb-2 shrink'>
        {leftContent.map((item, index) => {
          return <div key={index}>{item}</div>;
        })}
      </div>
      <div className='flex flex-wrap items-center justify-end flex-1 gap-3 pb-2 ml-auto align-middle'>
        {rightContent.map((item, index) => {
          return <div key={index}>{item}</div>;
        })}
      </div>
    </div>
  );
};

PageHeaderToolbar.propTypes = {
  leftContent: PropTypes.array,
  rightContent: PropTypes.array,
};

PageHeaderToolbar.defaultProps = {
  leftContent: [],
  rightContent: [],
};

export default PageHeaderToolbar;
