import React from "react";
import logo from 'LG_logo.svg';
import logoIcon from 'assets/css/icon.svg';

const Loading = ({ size }) => {

  if (size === 'full') {
    return (
      <div className="w-screen h-screen">
        <div className="flex items-center content-center justify-center h-full">
          <img src={logo} className="App-logo animate-bounce" alt="logo" />
        </div>
      </div>
    )
  }

  return (
    <div className='bg-growgreen-700 w-16 mx-auto rounded-full'>
      <img src={logoIcon} className="App-logo animate-spin" alt="logo" />
    </div>
  )
}

export default Loading;